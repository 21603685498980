import React, { Component, Fragment } from 'react'

export default class VideoJds extends Component {
  render() {
    
    return (
      <React.Fragment>
        <iframe width="560" height="415" src={`https://www.youtube.com/embed/${this.props.popularVideoJdJobs.split('=')[1]}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </React.Fragment>
    )
  }
}
