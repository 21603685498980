import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async';
import SponsoredJDs from '../../components/EmployerServices/SponsoredJDs'
export default class SponsoredJDss extends Component {
  render() {
    return (
        <React.Fragment>
        <Helmet >
            <title >Sponsored JDs | Rozgar.com</title>
            <meta name="HandheldFriendly" content="True" />
            <meta name="Keywords" content={"Sponsored JDs " + ", Jobs in India, Jobs in Noida, Search & Apply Job"}></meta>
            <meta name="description" content={"Company-Brandings " + " - Over the years, we have successfully built professional relationships with employers and job seekers using our full time hiring services in India. Our global outlook makes us one of the leading names in the recruiting market."} />
            <link   href={"https://www.rozgar.com/company-brandings"} />
            <meta name="referrer" content="no-referrer-when-downgrade" />
            <meta name="viewport" content="width=device-width, initial-scale=1"></meta>


            <meta property="og:site_name" content="Rozgar.com" />
            <meta property="og:title"content={ "Sponsored JDs | Rozgar.com"} />
            <meta property="og:description" content={ "Company-Brandings" + " - Over the years, we have successfully built professional relationships with employers and job seekers using our full time hiring services in India. Our global outlook makes us one of the leading names in the recruiting market."} />
            <meta property="og:url" content= {"https://www.rozgar.com/company-brandings"}  />
            <meta property="og:image" content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png" />
            <meta property="og:image:width" content="4000" />
            <meta property="og:image:height" content="6000" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={"Sponsored JDs | Rozgar.com"} />
            <meta name="twitter:description"  content={"Company-Brandings" + " - Over the years, we have successfully built professional relationships with employers and job seekers using our full time hiring services in India. Our global outlook makes us one of the leading names in the recruiting market."} />
            <meta name="twitter:url"content= {"https://www.rozgar.com/company-brandings"} />
            <meta name="twitter:image"  content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png"  />
            <meta name="twitter:label1" content="Written by" />
            <meta name="twitter:data1" content="Smita Nag" />
            <meta name="twitter:label2" content="Filed under" />
            <meta name="twitter:data2" content="Career Advice, Career Insights" />
            <meta name="twitter:site" content="@rozgar_india" />
        </Helmet>
        <SponsoredJDs/>
      </React.Fragment>
    )
  }
}
