import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async';
import constant from '../../constant';
import { topCompanyImages } from '../../action/dashboard';
import { latestfresherjobs, VideoJDJob, workFromHomejobs } from '../../action/jobsByActions';
import JobLists from '../../components/joblist/joblist';

export default class HotSectors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            JOB_LIST: [],
            JOB_COUNT: undefined,
            CURRENT_PAGE: 1,
            aboutJobName: 'India',
            isLocation: false,
            CITIES: [],
            TOP_COMPANY_IMAGES: [],
            locatlities: []
        }

    }
    componentDidMount() {
        this.setState({ aboutJobName: 'HotSectors Video JD Jobs' })
        document.title = constant.title.HotSectorVideoJdJob
        window.scrollTo(0, 0)
        this.joblist(this.state.CURRENT_PAGE, {})
        // isLocationUrl(this.props.match.params.url).then(res => {
        //     if (res.status) {
        //         this.setState({ isLocation: res.result.status, CITIES: res.result.cities, locatlities: res.result.locatlities })
        //     }
        //     else {
        //         alert(res.error)
        //     }
        // }).catch(err => {
        //     
        //     alert(err)
        // })

        topCompanyImages().then(res => {
            if (res.status) {
                this.setState({ TOP_COMPANY_IMAGES: res.result.images })
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })
    }
    joblist = (page, data) => {
        this.setState({ JOB_COUNT: undefined })
        this.setState({JOB_LIST: [],})
        VideoJDJob({ limit: 25, page: page, filter: data}).then(res => {
            if (res.status) {
                this.setState({ JOB_LIST: res.result.count ? res.result.list : undefined, JOB_COUNT: res.result.count })
            }
            else {
                alert(res.error)
            }

        }).catch(err => {
            alert(err)
        })
    }
    filterData = (data) => {

        this.joblist(1, data)
    }
  render() {
    const { JOB_LIST, JOB_COUNT, CURRENT_PAGE, aboutJobName, isLocation, CITIES, TOP_COMPANY_IMAGES, locatlities,latestfresherjobs } = this.state

    return (
     <React.Fragment>
           <Helmet >
    <script src="../../assets/js/vendor/jquery-library.js"></script>
    <script src="../../assets/js/vendor/bootstrap.min.js"></script>
    <script src="../../assets/js/jquery.basictable.min.js"></script>
    <script src="../../assets/js/jquery.sortable.js"></script>
    <script src="../../assets/js/jquery.collapse.js"></script>
    <script src="../../assets/js/owl.carousel.min.js"></script>
    <script src="../../assets/js/circle-progress.js"></script>
    <script src="../../assets/js/scrollbar.min.js"></script>
    <script src="../../assets/js/chosen.jquery.js"></script>
    <script src="../../assets/js/prettyPhoto.js"></script>
    <script src="../../assets/js/chartist.js"></script>
    <script src="../../assets/js/appear.js"></script>   
    <script src="../../assets/js/main.js"></script>
                <title data-react-helmet="true">HotSector Video JD Jobs List - Rozgar.com </title>
                <meta data-react-helmet="true" name="desccription" content="MY OTHER PAGE CONTENT" /> 

 
                <meta name="HandheldFriendly" content="True" />
                <meta name="Keywords" content={ 'HotSector Video JD Jobs'+"Jobs in India, Jobs in Noida, Search & Apply Job"}></meta>
                <meta name="description" content={ 'HotSector Video JD Jobs'+"Jobs in India, Jobs in Noida, Search & Apply Job Search, find and apply to job opportunities at Rozgar.com - India's leading Job Portal. Bring your perception, imagination and healthy neglect for the impossible. For query call us at +91-9311744658 or email us at contact@rozgar.com" } />
                <meta name="referrer" content="no-referrer-when-downgrade" />
                <meta property="og:site_name" content="Rozgar.com" />
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta property="og:title"content={"HotSector Video JD Jobs Jobs List - Rozgar.com"} />
                <meta property="og:description" content={  "HotSector Video JD Jobs Jobs List"+ "- Search, find and apply to job opportunities at Rozgar.com - India's leading Job Portal. Bring your perception, imagination and healthy neglect for the impossible. For query call us at +91-9311744658 or email us at contact@rozgar.com"} />
                <meta property="og:image:width" content="4000" />
                <meta property="og:image:height" content="6000" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={"HotSector Video JD Jobs List - Rozgar.com"} />
                <meta name="twitter:description"  content={  "HotSector Video JD Jobs List"+ "- Search, find and apply to job opportunities at Rozgar.com - India's leading Job Portal. Bring your perception, imagination and healthy neglect for the impossible. For query call us at +91-9311744658 or email us at contact@rozgar.com"} />
                <meta name="twitter:label1" content="Written by" />
                <meta name="twitter:data1" content="Smita Nag" />
                <meta name="twitter:label2" content="Filed under" />
                <meta name="twitter:data2" content="Career Advice, Career Insights" />
                <meta name="twitter:site" content="@rozgar_india" />
</Helmet>
                <JobLists
                    hideExperience={true}
                    JOB_LIST={JOB_LIST}
                    JOB_COUNT={JOB_COUNT}
                    joblist={(page, data) => { this.joblist(page, data) }}
                    CURRENT_PAGE={CURRENT_PAGE}
                    aboutJobName={aboutJobName}
                    isLocation={isLocation}
                    CITIES={CITIES}
                    TOP_COMPANY_IMAGES={TOP_COMPANY_IMAGES}
                    locatlities={locatlities}
                    filterData={(data) => this.filterData(data)}
                />
     </React.Fragment>
    )
  }
}
