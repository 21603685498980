import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async';
import InternationalWorkVisas from '../../components/InternationalWorkVisas/InternationalWorkVisas';
import constant from '../../constant';
import { capFirstLetterInSentence } from '../../utils';


export default class internationalWorkVisas extends Component {
    constructor(props){
        super(props);
        this.state={

        }
    }
    componentDidMount(){
        // document.title = constant.title.internationalWorkVisas
        window.scroll(0, 0)
    }
  render() {
    return (
      <React.Fragment>
                <Helmet >


<title >Work Abroad - Rozgar.com</title>
<meta name="HandheldFriendly" content="True" />
<meta name="Keywords" content={ "Work Abroad " + ", Jobs in India, Jobs in Noida, Search & Apply Job"}/>
<meta name="description" content={ "Work Abroad " + " - Do you want to build a career and life abroad? As one of the world’s leading overseas career specialists and a leading work visa agent, Rozgar has helped thousands of individuals and families settle in the world’s most liveable countries. We’ve seen firsthand how moving abroad can dramatically improve not just the migrant’s life but that of their family and parents. With our comprehensive overseas career solutions, we are the #1 choice for professionals seeking to work abroad.   "} />
<link  href={"https://www.rozgar.com/work-abroad/"} />
<meta name="referrer" content="no-referrer-when-downgrade" />
<meta name="viewport" content="width=device-width, initial-scale=1"/>


<meta property="og:site_name" content="Rozgar.com" />
<meta property="og:title"content={ "Work Abroad - Rozgar.com"} />
<meta property="og:description" content={ "Work Abroad" + " - Do you want to build a career and life abroad? As one of the world’s leading overseas career specialists and a leading work visa agent, Rozgar has helped thousands of individuals and families settle in the world’s most liveable countries. We’ve seen firsthand how moving abroad can dramatically improve not just the migrant’s life but that of their family and parents. With our comprehensive overseas career solutions, we are the #1 choice for professionals seeking to work abroad.   "} />
<meta property="og:url" content= {"https://www.rozgar.com/work-abroad/"}  />
<meta property="og:image" content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png" />
<meta property="og:image:width" content="4000" />
<meta property="og:image:height" content="6000" />

<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content={"Work Abroad  - Rozgar.com"} />
<meta name="twitter:description"  content={ "Work Visas " + " - Do you want to build a career and life abroad? As one of the world’s leading overseas career specialists and a leading work visa agent, Rozgar has helped thousands of individuals and families settle in the world’s most liveable countries. We’ve seen firsthand how moving abroad can dramatically improve not just the migrant’s life but that of their family and parents. With our comprehensive overseas career solutions, we are the #1 choice for professionals seeking to work abroad.   "} />
<meta name="twitter:url"content= {"https://www.rozgar.com/work-abroad/"} />
<meta name="twitter:image"  content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png"  />
<meta name="twitter:label1" content="Written by" />
<meta name="twitter:data1" content="Smita Nag" />
<meta name="twitter:label2" content="Filed under" />
<meta name="twitter:data2" content="Career Advice, Career Insights" />
<meta name="twitter:site" content="@rozgar_india" />
</Helmet>
        <InternationalWorkVisas/>
      </React.Fragment>
    )
  }
}
