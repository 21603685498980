import React, { Component, Suspense } from 'react'
import { jobCountByTopCategory, topCompanyList, topCompanyImages, topPremiumFeaturedCompanyList } from '../../action/dashboard';
import { premiumCityList } from '../../action/jobsByActions';
import Shimmer from '../../components/common/Shimmer';
import constant from '../../constant';
import HometabsWithShimmer from '../../components/home/hometabsWithShimmer'
import { MetaTags } from 'react-meta-tags';
import { Helmet } from 'react-helmet-async';
const Hometabs = React.lazy(() => import('../../components/home/hometabs'));

export default class homepage extends Component {
     
    constructor(props) {
         
        super(props);
        this.state = {
            LOCATION_LIST: null,
            JOB_COUNT_BY_TOP_CATEGORY: null,
            FEATURED_COMPANIES: null,
            PREMIUM_COMPANIES: null,
            TOP_COMPANY_IMAGES: null
        }
    }
    componentDidMount() {
         
        window.scrollTo(0, 0)
        // document.title = constant.title.Homepage
        this.getPremiumCityList()
         
        jobCountByTopCategory().then(res => {
            if (res.status) {
                this.setState({ JOB_COUNT_BY_TOP_CATEGORY: res.result })
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })

        topPremiumFeaturedCompanyList().then(res => {
            if (res.status) {
                this.setState({ FEATURED_COMPANIES: res.result.featured, PREMIUM_COMPANIES: res.result.premium })
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })


        topCompanyImages().then(res => {
            if (res.status) {
                this.setState({ TOP_COMPANY_IMAGES: res.result.images })
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })
    }

    getPremiumCityList = () => {
        premiumCityList().then(res => {
            if (res.status) {
                this.setState({ LOCATION_LIST: res.result })
            }
            else {
                alert(res.error)
            }
        }).catch(err => {
            alert(err)
        })
    }

    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    render() {
        const { LOCATION_LIST, JOB_COUNT_BY_TOP_CATEGORY, FEATURED_COMPANIES, PREMIUM_COMPANIES, TOP_COMPANY_IMAGES } = this.state

        return (<React.Fragment>
              <Helmet>


<title > Jobs - Recruitment - Job Search -  Employment - Job Vacancies - Rozgar.com</title>
<meta name="HandheldFriendly" content="True" />

<meta name="description" content={ "Jobs - Recruitment - Job Search -  Employment - Job Vacancies" + " - Search, find and apply to job opportunities at Rozgar.com - India's leading Job Portal. Bring your perception, imagination and healthy neglect for the impossible. For query call us at +91-9311744658 or email us at contact@rozgar.com"} />
<link  href={"https://www.rozgar.com"} />
<meta name="referrer" content="no-referrer-when-downgrade" />
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>

<meta property="og:site_name" content="Rozgar Official Blog" />
<meta property="og:type" content="article" />
<meta property="og:title"content={ "Jobs - Recruitment - Job Search -  Employment - Job Vacancies - Rozgar.com"} />
<meta property="og:description" content={ "Jobs - Recruitment - Job Search -  Employment - Job Vacancies" + " - Search, find and apply to job opportunities at Rozgar.com - India's leading Job Portal. Bring your perception, imagination and healthy neglect for the impossible. For query call us at +91-9311744658 or email us at contact@rozgar.com"} />
<meta property="og:url" content= {"https://www.rozgar.com"}  />
<meta property="og:image" content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png" />


<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content={"Jobs - Recruitment - Job Search -  Employment - Job Vacancies - Rozgar.com"} />
<meta name="twitter:description"  content={ "Jobs - Recruitment - Job Search -  Employment - Job Vacancies" + " - Search, find and apply to job opportunities at Rozgar.com - India's leading Job Portal. Bring your perception, imagination and healthy neglect for the impossible. For query call us at +91-9311744658 or email us at contact@rozgar.com"} />
<meta name="twitter:url"content= {"https://www.rozgar.com"} />
<meta name="twitter:image"  content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png"  />
<meta name="twitter:label1" content="Written by" />
<meta name="twitter:data1" content="Smita Nag" />
<meta name="twitter:label2" content="Filed under" />
<meta name="twitter:data2" content="Career Advice, Career Insights" />
<meta name="twitter:site" content="@rozgar_india" />
<meta property="og:image:width" content="4000" />
<meta property="og:image:height" content="6000" />
</Helmet>
            
               
                <div id="parentdiv">
                {/* {LOCATION_LIST === null || JOB_COUNT_BY_TOP_CATEGORY === null || FEATURED_COMPANIES === null
                    || PREMIUM_COMPANIES === null || TOP_COMPANY_IMAGES === null ||
                    <HometabsWithShimmer
                        JOB_COUNT_BY_TOP_CATEGORY={JOB_COUNT_BY_TOP_CATEGORY}
                        LOCATION_LIST={LOCATION_LIST}
                        FEATURED_COMPANIES={FEATURED_COMPANIES}
                        PREMIUM_COMPANIES={PREMIUM_COMPANIES}
                        TOP_COMPANY_IMAGES={TOP_COMPANY_IMAGES}
                    />} */}
                {
                    // LOCATION_LIST && JOB_COUNT_BY_TOP_CATEGORY && FEATURED_COMPANIES && PREMIUM_COMPANIES && TOP_COMPANY_IMAGES &&

                    
                     <Suspense fallback={<React.Fragment> 
                         <HometabsWithShimmer
                        JOB_COUNT_BY_TOP_CATEGORY={JOB_COUNT_BY_TOP_CATEGORY}
                        LOCATION_LIST={LOCATION_LIST}
                        FEATURED_COMPANIES={FEATURED_COMPANIES}
                        PREMIUM_COMPANIES={PREMIUM_COMPANIES}
                        TOP_COMPANY_IMAGES={TOP_COMPANY_IMAGES}  />
                     </React.Fragment>   }  >
                    
                        <Hometabs
                            history={this.props.history}
                            // JOB_COUNT_BY_TOP_CATEGORY={JOB_COUNT_BY_TOP_CATEGORY}
                            // LOCATION_LIST={LOCATION_LIST}
                            FEATURED_COMPANIES={FEATURED_COMPANIES}
                            PREMIUM_COMPANIES={PREMIUM_COMPANIES}
                            TOP_COMPANY_IMAGES={TOP_COMPANY_IMAGES}
                        // getPremiumCityList={this.getPremiumCityList}
                        />
                    </Suspense>

                }
            </div>
        </React.Fragment>

        )
    }
}
