import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { LinkedInLoginAuth } from '../../action/CandidateAction'
import constant from '../../constant'

const Index = () => {
    const location = useLocation()
    const history = useHistory()
    useEffect(() => {
        const qParam = queryString.parse(location.search)
        qParam.redirect_uri = `${window.location.origin}/linkedin`
        LinkedInLoginAuth(qParam).then((res => {
            if (res.status) {
                history.push(constant.component.editProfile.url)
            }
        }))
    }, [])
    return (
        <React.Fragment>
            <h1>Please wait Logging in</h1>
        </React.Fragment >
    )
}

export default Index