import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async';
import JobsByCategory from '../../components/jobsByCategory/jobsByCategory'
import constant from '../../constant'
export default class jobsByCategory extends Component {
    constructor(props){
        super(props);
        this.state={

        }
    }
    componentDidMount(){
        document.title = constant.title.JobsByCategory
        window.scrollTo(0, 0)
    }
  render() {
    return (
      <React.Fragment>


<Helmet>
                 <title > Browse Jobs by Company, Location, Skills, Designation & Industry | Rozgar.com Overview  – Jobs, Work Culture - Rozgar.com</title>
                 <meta name="Keywords" content={ "Browse Jobs by Company, Location, Skills, Designation & Industry" + " Overview – Know more about job opportunities, employee feedback and Ratings, work culture, immediate hirings etc at Rozgar.com."}></meta>
                 <meta name="description" content={"Browse Jobs by Company, Location, Skills, Designation & Industry" + "- Search, find and apply to job opportunities at Rozgar.com - India's leading Job Portal. Bring your perception, imagination and healthy neglect for the impossible. For query call us at +91-9311744658 or email us at contact@rozgar.com"} />
                   <link rel="canonical"  href={"https://www.rozgar.com/jobs-by-skill"} />
                    <meta name="atdlayout" content="jobsearch" />
                    <meta name="viewport" content="width=device-width, initial-scale=1"/>
                    <meta name="robots" content="ALL" />
                    <meta name="classification" content="Jobs &amp; Career Opportunities: Post a job , Career Explorer, Job Search, Apply Jobs, Create Free CV" />
                    <meta name="pragma" content="no-cache" />
                    <meta name="rating" content="general" />
                    <meta name="revisit-after" content="1 day" />
                    <meta name="distribution" content="GLOBAL" />
                    <meta name="resource-type" content="document" />
                    <meta name="author" content="www.rozgar.com" />
                    <meta name="content-language" content="EN" />
                    <meta name="copyright" content="2022 Rozgar.com" />

                    <meta property="fb:app_id" content="2077422969016028" />
                    <meta property="og:image" content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png" />
                    <meta property="og:url" content={"https://www.rozgar.com/jobs-by-skill" }/>
                    <meta property="og:site_name" content="Rozgar.com" />
                    <meta property="og:description" content={"Browse Jobs by Company, Location, Skills, Designation & Industry" + "- Search, find and apply to job opportunities at Rozgar.com - India's leading Job Portal. Bring your perception, imagination and healthy neglect for the impossible. For query call us at +91-9311744658 or email us at contact@rozgar.com"} />
                    <meta property="og:title" content={"Browse Jobs by Company, Location, Skills, Designation & Industry | Rozgar.com Overview  – Jobs, Work Culture - Rozgar.com"} />

                </Helmet>
          <JobsByCategory/>
      </React.Fragment>
    )
  }
}
