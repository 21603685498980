import React, { Component } from 'react'
import { Helmet } from 'react-helmet';

import UpgradeSkill from '../../components/StudyAbroad/UpgradeSkill'
import constant from '../../constant';
export default class UpgradeSkills extends Component {
    constructor(props) {
        super(props);
        this.state = {
        
    
        }
      }
      componentDidMount() {
      
        document.title = constant.title.UpgradeSkills
      }
  render() {
    return (
    <React.Fragment>
        <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        </Helmet>
        <UpgradeSkill/>
    </React.Fragment>
    )
  }
}

