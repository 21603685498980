import React, { Component } from 'react'
import SendEnquiry from '../../components/Enquiry/SendEnquiry'
import PayrollAccuracy from '../../assets/images/PR.png'
import ReducedPayrollCost from '../../assets/images/SC2.png'
import ComplianceAndPayrollManagement from '../../assets/images/taxpay.png'
import OfferedYou5 from '../../assets/images/offered-you5.jpg'
import faqs2 from '../../assets/images/faqs2.jpg'

export default class Payrollautomation extends Component {

    render() {
        return (
            <React.Fragment>
                <div id='rg-innerbannervtwo' className='rg-enquiryrvtworep rg-hed-section'>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-8">
                                <div className='page-header-section'>
                                    <div className='page-header-content'>
                                        <h4>3rd Party Payroll Outsourcing<br />
                                            Employee Management Solutions</h4>
                                        <p>Simplifying payroll management for your organisation</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SendEnquiry />
                <main id="rg-main" className="rg-main rg-haslayout pt-0">
                    <div className="rg-share-your-interview  rg-haslayout pt-0">
                        <div className="rozgar-distinguishing-expertise">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">

                                        <div className='usp-top-box'>
                                            <div className='main-head-box'>
                                                <h3>Our Distinguishing Expertise</h3>
                                                <div className='feature-line'>
                                                    <span className='animate-bar'></span>
                                                </div>
                                            </div>
                                            <div className='usp-main-item'>
                                                <div className='usp-item-box'>
                                                    <div className='icon-img'><img src={PayrollAccuracy} /></div>
                                                    <h4>Payroll Accuracy</h4>
                                                    <div className='feature-line'>
                                                        <span className='animate-bar'></span>
                                                    </div>
                                                    <p>Our 500+ happy clients confirms our accuracy in payroll management</p>
                                                </div>
                                                <div className='usp-item-box'>
                                                    <div className='icon-img'><img src={ReducedPayrollCost} /></div>
                                                    <h4>Reduced payroll cost</h4>
                                                    <div className='feature-line'>
                                                        <span className='animate-bar delay-1-0s'></span>
                                                    </div>
                                                    <p>Time and cost are essential; we optimize both</p>
                                                </div>
                                                <div className='usp-item-box'>
                                                    <div className='icon-img'><img src={ComplianceAndPayrollManagement} /></div>
                                                    <h4>Experts in Tax, Compliance and Payroll Management</h4>
                                                    <div className='feature-line'>
                                                        <span className='animate-bar delay-2-0s'></span>
                                                    </div>
                                                    <p>14 years of industry-oriented experience</p>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <section className='secrtion-bg-gray pddding-top-btm'>
                            <div className='container'>
                                <div className='row mt-5 is-active' id='slide01'>
                                    <div className='col-md-6 col-sm-4 mb-5'>
                                        <div className='one-side-image-bx'>
                                            <img src={OfferedYou5} className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-sm-4  mb-5'>
                                        <div className='content-page-box'>
                                            <div className='main-head-box'>
                                                <h3>Our Offered Services</h3>
                                                <div className='feature-line'>
                                                    <span className='animate-bar'></span>
                                                </div>
                                            </div>
                                            <p>Mount Talent Consulting is one of the <b>best third-party payroll companies</b> with years of experience and professional expert team members. We assure offering the following services</p>
                                            <ul className='list-offer'>
                                                <li>Ensuring end-to-end payroll with effective reimbursement management</li>
                                                <li>Full and final settlement, within 45-days, after exit clearance</li>
                                                <li>Web-based attendance checking and leave solutions</li>
                                                <li>Offering customized and personalized payroll employee management solutions to companies</li>
                                                <li>PF Challan before 15th</li>
                                                <li>Systematic management of offer letters, ID cards, relieving letters, etc.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className='cmt-row about-section clearfix'>
                            <div className='container'>
                                <div className='row align-items-center'>
                                    <div className='col-xl-12 col-md-12'>
                                        <div className='section-title style2 res-991-pb-15 text-center'>
                                            <div className='title-header'>
                                                <h4 className='title'>Benefits and needs of 3rd party payroll outsourcing / employee management
                                                </h4>
                                            </div>
                                            <div className='feature-line'>
                                                <span className='animate-bar'></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb_15'>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-one'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3><a href=''>Elimination Of Manual Errors</a></h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>We invest our best efforts to eliminate human error; assuring maximum satisfaction</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-two'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3><a href=''>Well-Organized Resolution To Employee Issues</a></h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>3rd party payroll ensures quick, systemic, and well-organized solutions to all the varying payroll-related issues of the employees</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-three'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3><a href=''>Accurate Documentation And Reports</a></h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>The employee management services confirm accuracy in maintaining proper documentation and reports of all the employees</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-four'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3><a href=''>Time And Cost-Efficient</a></h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Choosing a third party payroll outsourcing company minimizes the expenses and the hidden cost of in-house payroll management with the time-saving offered services</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-five'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3><a href=''>Speeds Response</a></h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>The third-party payroll outsourcing services ensures offering a speedy response to all the concerning issues of the employees</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-six'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3><a href=''>Strategic Recruitment Management</a></h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Expect proper assistance for recruitment management right from recruiting, on-boarding, and retaining the employees to their exit</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className='cmt-row about-section clearfix secrtion-bg-gray'>
                            <div className='container'>
                                <div className='row align-items-center'>
                                    <div className='col-xl-12 col-md-12'>
                                        <div className='section-title style2 res-991-pb-15 text-center'>
                                            <div className='title-header'>
                                                <h4 className='title'>Why Choose MTC for 3rd party payroll outsourcing and employee management?</h4>
                                            </div>
                                            <div className='feature-line'>
                                                <span className='animate-bar'></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb_15'>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 staffing-height'>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <h3><a href=''>Fast Turnaround Time</a></h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>We assure providing you with consistent and relevant third party payroll outsourcing services within no time</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 staffing-height'>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <h3><a href=''>100% Guaranteed Satisfaction And Accuracy</a></h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Unless and until you are satisfied, we won't stop. We will make it right and accurate with our consistent dedicated efforts</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 staffing-height'>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <h3><a href=''>Our Team Of Experts</a></h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>We have the best professional team of experts offering consistent payroll solutions with their industry-oriented experience</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 staffing-height'>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <h3><a href=''>24*7 Support And Assistance</a></h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Expect nothing other than 24*7 support and assistance from our experts. We make sure we are always available for you!</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 staffing-height'>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <h3><a href=''>Client-Focused Approach</a></h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Have specific payroll employee management needs? Don't worry; trust our client-focused approach!

                                                        We guarantee reliable results to let you count on our 3rd party payroll services again and again.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className='pddding-top-btm'>
                            <div className='container'>
                                <div className='row mt-5 ' id='slide01'>
                                    <div className='col-md-6 col-sm-4 mb-5'>
                                        <div className='one-side-image-bx'>
                                            <img src={faqs2} />
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-sm-6  mb-5'>
                                        <div className='content-page-box'>
                                            <div className='main-head-box'>
                                                <h3>FAQs</h3>
                                                <div className='feature-line'>
                                                    <span className='animate-bar'></span>
                                                </div>
                                            </div>
                                            <div className='faqscontent'>
                                                <div>
                                                    <input type='checkbox' id='question1' name='q' className='questions' />
                                                    <div className='plus'>+</div>
                                                    <label for='question1' className='question'>

                                                        What is a 3rd party payroll system?
                                                    </label>
                                                    <div class="answers">
                                                        <p>It is the process of outsourcing and managing the complete payroll responsibilities of the employer organization. The <b>3rd party payroll provider</b> like Mount Talent Consulting ensures fulfilling all the requirements for maintaining efficient professional relationships. </p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <input type='checkbox' id='question2' name='q' className='questions' />
                                                    <div className='plus'>+</div>
                                                    <label for='question2' className='question'>

                                                        Is 3rd party payroll good?
                                                    </label>
                                                    <div class="answers">
                                                        <p>Relying on Mount Talent Consulting - the <b>top third party payroll outsourcing company</b> helps cut the company costs by 18% compared to the in-house payroll processing. You can focus on your business's core functionalities while our experts take over the payroll requirements.
                                                        </p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <input type='checkbox' id='question3' name='q' className='questions' />
                                                    <div className='plus'>+</div>
                                                    <label for='question3' className='question'>

                                                        What is Team Lease payroll?
                                                    </label>
                                                    <div class="answers">
                                                        <p>It offers the required <b>third-party payroll services</b> to large and small companies for a fixed price. The price can vary depending upon the total number of employees and the overall data processed.</p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <input type='checkbox' id='question4' name='q' className='questions' />
                                                    <div className='plus'>+</div>
                                                    <label for='question4' className='question'>

                                                        What is the difference between on-roll and off-role jobs?
                                                    </label>
                                                    <div class="answers">
                                                        <p>For the off-role jobs, employees work only for an undefined time with no job experience and less growth opportunities. On the other hand, employees can enjoy assured growth for the on-roll positions.
                                                            .
                                                        </p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <input type='checkbox' id='question5' name='q' className='questions' />
                                                    <div className='plus'>+</div>
                                                    <label for='question5' className='question'>

                                                        Is it good to join as a contract employee?
                                                    </label>
                                                    <div className='answers'>
                                                        <p>Contrary to traditional belief, contract employees enjoy better benefits than permanent employees because of their vast professional network. Mount Talent Consulting can offer the <b>best third-party payroll outsourcing services</b> for permanent as well as contractual employees.
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </main>
            </React.Fragment>
        )
    }
}
