import React, { Component } from "react";
import { Helmet } from "react-helmet";
import swal from "sweetalert";
import { getResumeData, UpdateResume, WriteResume } from "../../action/CandidateAction";
import TemplateEdit01 from "../../components/ResumeMaking/TemplateEdit01";
import resume02 from "../../assets/img/demos/demo-view-2.png";
import constant from "../../constant";
import { capFirstLetterInSentence, getStorage } from "../../utils";
import ResumeForm1 from "../../components/ResumeMaking/ResumeViewOne/ResumeForm1";
import bg4r from '../../assets/images/bg-resume.jpg'

export default class UpdateTemplate01 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showShimmer: false,
      showLoader: false,
      detail: undefined,
      footer_style :{
          style:"marginTop:400px"
      }, 
      candidateID: getStorage(constant.keys.cd)
        ? getStorage(constant.keys.cd)
        : "",
    };
   
  }


  componentDidMount() {

    // document.title = constant.title.TemplateUpdate02
    const CANDIDATEID = this.state.candidateID.CANDIDATE_ID;
    this.ResumeDetail(CANDIDATEID);
  }

  ResumeDetail = (CANDIDATEID) => {
    this.setState({ showShimmer: true });
    getResumeData(CANDIDATEID)
      .then((res) => {
        this.setState({ showShimmer: false });
        if (res.status) {
          this.setState({ showShimmer: false });
          this.setState({ detail: res.result });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };
  addResumeMaking = (model) => {
    this.setState({ showLoader: true })
    WriteResume(model).then((res) => {
      this.setState({ showLoader: false })
      if (res.status) {
        this.setState({ showLoader: false })
        swal({
          icon: 'success',
          text: 'Resume created successfully',
          timer: 3000,
        })
        this.props.history.push(constant.component.ResumeChooseTemplate.url)
      }
      else {
        this.setState({ showLoader: false })
        swal({
          icon: 'error',
          text: res.error,
          timer: 2000,
        })
      }
    }).catch((err) => {
      console.log(err)
    })
  }


  updateResumeMaking = (model) => {

    this.setState({ showLoader: true });
    UpdateResume(model)
      .then((res) => {
        this.setState({ showLoader: false });
        if (res.status) {
          this.setState({ showLoader: false });
          swal({
            icon: "success",
            text: "",
            timer: 3000,
          });
          this.props.history.push(constant.component.ResumeChooseTemplate.url);
        } else {
          this.setState({ showLoader: false });
          swal({
            icon: "error",
            text: res.error,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { hash } = this.props.location
   
  
    return (
      <React.Fragment>
        <Helmet>
          <title
            title={
              " Create Free CV - " +
              capFirstLetterInSentence(
                window.location.pathname
                  .replace("/", "")
                  .split("/")
                  .pop()
                  .split("-")
                  .join(" ")
              ) +
              "-Rozgar.com"
            }
          >
            {" Create Free CV - " +
              capFirstLetterInSentence(
                window.location.pathname
                  .replace("/", "")
                  .split("/")
                  .pop()
                  .split("-")
                  .join(" ")
              ) +
              "-Rozgar.com"}
          </title>
          <meta name="HandheldFriendly" content="True" />
          <meta
            name="Keywords"
            content={
              capFirstLetterInSentence(
                window.location.pathname
                  .replace("/", "")
                  .split("/")
                  .pop()
                  .split("-")
                  .join(" ")
              ) +
              " Create a CV in Minutes, Free CV Builder, Free CV Templates, Free Online CV Generator, Create Free Resume Online, Rozgar CV"
            }
          ></meta>
          <meta
            name="description"
            content={
              " Create your Free CV Online - Use " +
              capFirstLetterInSentence(
                window.location.pathname
                  .replace("/", "")
                  .split("/")
                  .pop()
                  .split("-")
                  .join(" ")
              ) +
              "for Free Online CV maker, allows you to create a perfect Resume in less than 5 minutes. See how easy it is to write a professional resume. Focus on building a career, not your CV. Create CV now!"
            }
          />
          <link rel="canonical" href={window.location.href} />
          <meta name="referrer" content="no-referrer-when-downgrade" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />

          {/* og meta tag */}
          <meta property="og:site_name" content="Rozgar.com" />
          <meta
            property="og:title"
            content={capFirstLetterInSentence(
              window.location.pathname
                .replace("/", "")
                .split("/")
                .pop()
                .split("-")
                .join(" ")
            )}
          />
          <meta
            property="og:description"
            content={
              " Create your Free CV Online - Use " +
              capFirstLetterInSentence(
                window.location.pathname
                  .replace("/", "")
                  .split("/")
                  .pop()
                  .split("-")
                  .join(" ")
              ) +
              "for Free Online CV maker, allows you to create a perfect Resume in less than 5 minutes. See how easy it is to write a professional resume. Focus on building a career, not your CV. Create CV now!"
            }
          />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:image"
            content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png"
          />
          <meta property="og:image:width" content="4000" />
          <meta property="og:image:height" content="6000" />

          {/* Twitter Meta Tag */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content={capFirstLetterInSentence(
              window.location.pathname
                .replace("/", "")
                .split("/")
                .pop()
                .split("-")
                .join(" ")
            )}
          />
          <meta
            name="twitter:description"
            content={
              " Create your Free CV Online - Use " +
              capFirstLetterInSentence(
                window.location.pathname
                  .replace("/", "")
                  .split("/")
                  .pop()
                  .split("-")
                  .join(" ")
              ) +
              "for Free Online CV maker, allows you to create a perfect Resume in less than 5 minutes. See how easy it is to write a professional resume. Focus on building a career, not your CV. Create CV now!"
            }
          />
          <meta name="twitter:url" content={window.location.href} />
          <meta
            name="twitter:image"
            content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png"
          />
          <meta name="twitter:label1" content="Written by" />
          <meta name="twitter:data1" content="Smita Nag" />
          <meta name="twitter:label2" content="Filed under" />
          <meta name="twitter:data2" content="Career Advice, Career Insights" />
          <meta name="twitter:site" content="@rozgar_india" />
        </Helmet>
        <section className="blog-area">
          <div className="container-fluid">
            <div className="row">

              <div className="col-12 col-md-4">

                <div className="cv-prev bg-white  cv-prev-right-bx p-4" data-bg-image={bg4r} style={{
										background: "#ffffff url(" + bg4r + ")",
										backgroundPosition: 'bottom 10px right 10px',
										backgroundSize: '50%',
										backgroundRepeat: 'no-repeat'
									}}>
                  <div className="left-had-sec"><h1>Create Your Resume</h1></div>
                  <div className="blog_thumbnail resume-making-tab-box navigation"  id="mainNav">

                    {/* <img src={resume02} className="temp-img" alt="" /> */}

                   <ul className="right-bar">
                      <li>
                        <a className="navigation__link" href='#1'> Personal Info</a>
                      </li>
                      <li>
                        <a className="navigation__link"  href='#2'>Add Educations </a>
                      </li>
                      <li>
                        <a className="navigation__link"  href='#3'>Add Experiences</a>
                      </li>
                      <li>
                        <a className="navigation__link"  href='#4'>Add Projects</a>
                      </li>
                      <li>
                        <a className="navigation__link"  href='#5'> Add Skills</a>
                      </li>
                      <li>
                        <a className="navigation__link"  href='#6'>Add Languages</a>
                      </li>
                      <li>
                        <a className="navigation__link"  href='#7'>Add Social Links </a>
                      </li>
                      <li>
                        <a className="navigation__link"  href='#8'>Add Interests  </a>
                      </li>
                      
                    </ul>	
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-8">
                <div className="container">
                  {this.state.candidateID.toString().length == 0 && (
                    <ResumeForm1
                      //   details={this.state.detail}
                      onSubmit={(model) => this.addResumeMaking(model)}
                      showLoader={this.state.showLoader}
                      showShimmer={this.state.showShimmer}
                    />
                  )}
                  {this.state.detail && (
                    <ResumeForm1
                      details={this.state.detail}
                      onSubmit={(model) => this.updateResumeMaking(model)}
                      showLoader={this.state.showLoader}
                      showShimmer={this.state.showShimmer}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
