import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async';
import CareerAstrology from '../../components/common/CareerAstrology/CareerAstrology';
import constant from '../../constant';
import { capFirstLetterInSentence } from '../../utils';

export default class careerAstrology extends Component {
    constructor(props){
        super(props);
        this.state={

        }
    }
    componentDidMount(){
        // document.title = constant.title.careerAstrology
        window.scroll(0, 0)
    }
  render() {
    return (
      <React.Fragment>
           <Helmet >


           <title >Career Astrology | Rozgar.com </title>
           <meta name="HandheldFriendly" content="True" />
           <meta name="Keywords" content={ "Career-Astrology" + ", Jobs in India, Jobs in Noida, Search & Apply Job"}></meta>
           <meta name="description" content={ "Career-Astrology" + " - Choose our premium services consisting of Personal Astrology Services, Finance Astrology Services, Education Astrology Services and so others made specifically for you to get the desired solution in life on the front of personal life, finance or academic pursuit."} />
           <link  href={"https://www.rozgar.com/career-astrology"} />
           <meta name="referrer" content="no-referrer-when-downgrade" />
           <meta name="viewport" content="width=device-width, initial-scale=1"/>
           
           <meta property="og:site_name" content="Rozgar.com" />
           <meta property="og:title"content={"Career Astrology | Rozgar.com"} />
           <meta property="og:description" content={ "Career-Astrology" + " - Choose our premium services consisting of Personal Astrology Services, Finance Astrology Services, Education Astrology Services and so others made specifically for you to get the desired solution in life on the front of personal life, finance or academic pursuit."} />
           <meta property="og:url" content= {"https://www.rozgar.com/career-astrology"}  />
           <meta property="og:image" content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png" />
           <meta property="og:image:width" content="4000" />
           <meta property="og:image:height" content="6000" />
           
           <meta name="twitter:card" content="summary_large_image" />
           <meta name="twitter:title" content={"Career Astrology | Rozgar.com"} />
           <meta name="twitter:description"  content={ "Career-Astrology" + " - Choose our premium services consisting of Personal Astrology Services, Finance Astrology Services, Education Astrology Services and so others made specifically for you to get the desired solution in life on the front of personal life, finance or academic pursuit."} />
           <meta name="twitter:url"content= {"https://www.rozgar.com/career-astrology"} />
           <meta name="twitter:image"  content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png"  />
           <meta name="twitter:label1" content="Written by" />
           <meta name="twitter:data1" content="Smita Nag" />
           <meta name="twitter:label2" content="Filed under" />
           <meta name="twitter:data2" content="Career Advice, Career Insights" />
           <meta name="twitter:site" content="@rozgar_india" />
</Helmet>
        <CareerAstrology/>
      </React.Fragment>
    )
  }
}
