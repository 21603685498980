import React, { Component } from 'react'
import SendEnquiry from '../../components/Enquiry/SendEnquiry'
import SC2 from '../../assets/images/SC2.png'
import EA2 from '../../assets/images/EA2.png'
import tm from '../../assets/images/tm.png'
import staffAugmentation2 from '../../assets/images/staff-augmentation2.jpg'
import faqs2 from '../../assets/images/faqs2.jpg'

export default class ContractualStaffing extends Component {
    render() {
        return (

            <React.Fragment>
                <div id='rg-innerbannervtwo' className='rg-enquiryrvtworep rg-hed-section'>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-8">
                                <div className='page-header-section'>
                                    <div className='page-header-content'>
                                        <h4>Contract Staffing's</h4>
                                        <p>World-Class Talent For Your Excellent Workforce</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SendEnquiry/>
                <main id="rg-main" className="rg-main rg-haslayout pt-0">
                    <div className="rg-share-your-interview  rg-haslayout pt-0">
                        <div className="rozgar-distinguishing-expertise">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">

                                        <div className='usp-top-box'>
                                            <div className='main-head-box'>
                                                <h3>Our Distinguish Skills</h3>
                                                <div className='feature-line'>
                                                    <span className='animate-bar'></span>
                                                </div>
                                            </div>
                                            <div className='usp-main-item'>
                                                <div className='usp-item-box'>
                                                    <div className='icon-img'><img src={SC2} /></div>
                                                    <h4>Cost-Effective</h4>
                                                    <div className='feature-line'>
                                                        <span className='animate-bar'></span>
                                                    </div>
                                                    <p>Benefit from cost-free new employee training and flexible hiring at rates that are competitive with those in the market.</p>
                                                </div>
                                                <div className='usp-item-box'>
                                                    <div className='icon-img'><img src={EA2} /></div>
                                                    <h4>Flexible Professional Hiring</h4>
                                                    <div className='feature-line'>
                                                        <span className='animate-bar delay-1-0s'></span>
                                                    </div>
                                                    <p>We provide the most consistent candidates with great expertise and skills for every domain.</p>
                                                </div>
                                                <div className='usp-item-box'>
                                                    <div className='icon-img'><img src={tm} /></div>
                                                    <h4>Excellent Team Management</h4>
                                                    <div className='feature-line'>
                                                        <span className='animate-bar delay-2-0s'></span>
                                                    </div>
                                                    <p>Reduced turnover rates to boost project output and productivity.</p>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <section className='secrtion-bg-gray pddding-top-btm'>
                            <div className='container'>
                                <div className='row mt-5 is-active' id='slide01'>
                                    <div className='col-md-6 col-sm-4 mb-5'>
                                        <div className='one-side-image-bx'>
                                            <img src={staffAugmentation2} className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-sm-4  mb-5'>
                                        <div className='content-page-box'>
                                            <div className='main-head-box'>
                                                <h3>Services We Offer</h3>
                                                <div className='feature-line'>
                                                    <span className='animate-bar'></span>
                                                </div>
                                            </div>
                                            <p className="pb-2">Rozgar.com is a well-known provider of staffing solutions. We put a strong emphasis on providing the best talent to boost your business's productivity, growth, and evolution.</p>
                                            <ul className='list-offer'>
                                                <li>Recruiting and hiring experienced candidates.</li>
                                                <li>Providing candidates who are well-suited to your project's requirements.</li>
                                                <li>Retaining the best employees.</li>
                                                <li>Supplying the appropriate skills to meet your company's needs.</li>
                                                <li>Locating, selecting, screening, and hiring the ideal candidate with the appropriate skill set and experience.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className='cmt-row about-section clearfix'>
                            <div className='container'>
                                <div className='row align-items-center'>
                                    <div className='col-xl-12 col-md-12'>
                                        <div className='section-title style2 res-991-pb-15 text-center'>
                                            <div className='title-header'>
                                                <h4 className='title'>What Is The Need for Contract Staffing?</h4>
                                            </div>
                                            <div className='feature-line'>
                                                <span className='animate-bar'></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb_15'>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-one'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3>Time Efficiency</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Contract staffing reduces the impending stress of finding a full-time candidate.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-two'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3>Selection Of The Master Candidate</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>A business can focus on business-centric needs with the assistance of contract staffing and complete the project within the allotted time frames.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-three'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3>Ascendible</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>This service brings high-quality solutions to the day-to-day needs of a specialised workforce without any long-term commitment.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6 offset-2'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-four'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3>Cost-Effective</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>We provide you with a team of experienced professionals for your consistent workflow by shortening the lengthy and complicated process of the traditional hiring system with our individualised technology.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-five'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3>Endless Support</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Transparent communication and ongoing resource flow make it possible to maintain uninterrupted command and support for development projects.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className='cmt-row about-section clearfix secrtion-bg-gray'>
                            <div className='container'>
                                <div className='row align-items-center'>
                                    <div className='col-xl-12 col-md-12'>
                                        <div className='section-title style2 res-991-pb-15 text-center'>
                                            <div className='title-header'>
                                                <h4 className='title'>Why Choose Rozgar.com For Contract Staffing?</h4>
                                            </div>
                                            <div className='feature-line'>
                                                <span className='animate-bar'></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb_15'>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 staffing-height'>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <h3>Years of Expertise</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Due to its market-oriented experience in providing skilled, trained, and experienced working professionals, Rozgar.com is the industry leader.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 staffing-height'>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <h3>Team Of Professionals</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Rozgar.com has the best HR professionals and experienced recruiters in the business to provide you with a team of the best talent.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 staffing-height'>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <h3>Reasonable Service</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>You won't believe it, but our cost-effective contract staffing services won't let you down. Our quick turnaround time and 100% guarantee of satisfaction set us apart.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6 offset-4'>
                                        <div className='featured-icon-box icon-align-before-content style4 staffing-height'>
                                            <div className='featured-content'>
                                                <div className='featured-title'>
                                                    <h3>24*7 Assistance</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>We are online 24*7, so any problems, concerns or queries you may have we will assist you no matter the time or place. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className='pddding-top-btm'>
                            <div className='container'>
                                <div className='row mt-5 ' id='slide01'>
                                    <div className='col-md-5 col-sm-4 mb-5'>
                                        <div className='one-side-image-bx'>
                                            <img src={faqs2} style={{maxWidth:'380px'}} />
                                        </div>
                                    </div>
                                    <div className='col-md-7 col-sm-6  mb-5'>
                                        <div className='content-page-box'>
                                            <div className='main-head-box'>
                                                <h3>FAQs</h3>
                                                <div className='feature-line'>
                                                    <span className='animate-bar'></span>
                                                </div>
                                            </div>
                                            <div className='faqscontent'>
                                                <div>
                                                    <input type='checkbox' id='question1' name='q' className='questions' />
                                                    <div className='plus'>+</div>
                                                    <label for='question1' className='question'>
                                                        What Is The Meaning Of Contract Staffing?
                                                    </label>
                                                    <div className='answers'>
                                                        <p>Outsourcing staffing services to hire and recruit the right talent for a specific project to fulfil the business goals is called contract staffing.</p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <input type='checkbox' id='question2' name='q' className='questions' />
                                                    <div className='plus'>+</div>
                                                    <label for='question2' className='question'>
                                                    Why Do A Company Need Contract Staffing?
                                                    </label>
                                                    <div className='answers'>
                                                        <p>Contract staffing is required to add flexibility to the existing team. The process makes sure that all staffing needs are fulfilled for the short term. It saves both time and cost.</p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <input type='checkbox' id='question3' name='q' className='questions' />
                                                    <div className='plus'>+</div>
                                                    <label for='question3' className='question'>
                                                        Why Contract Staffing Is Important?
                                                    </label>
                                                    <div className='answers'>
                                                        <p>Contract staffing gives better control of a company on their projects by hiring a temporary but best team of talents. It assures timely delivery of projects without stressing the existing full-time workforce.</p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <input type='checkbox' id='question4' name='q' className='questions' />
                                                    <div className='plus'>+</div>
                                                    <label for='question4' className='question'>
                                                    Why Use Contract Staffing?
                                                    </label>
                                                    <div className='answers'>
                                                        <p>Organisations use it when in-house specialists and experts are unavailable for a specific project. A reliable staffing solution provider and their contract staffing fulfil the need quickly.</p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <input type='checkbox' id='question5' name='q' className='questions' />
                                                    <div className='plus'>+</div>
                                                    <label for='question5' className='question'>
                                                    What Does Staffing Means?
                                                    </label>
                                                    <div className='answers'>
                                                        <p>The process of hiring the right candidate with the right skills, expertise and qualifications for a particular job role and position is called staffing. Its role is to assess their ability before offering them the role.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </main>
            </React.Fragment>
        )
    }
}
