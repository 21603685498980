import React, { Component } from 'react'
import { getCollegeList } from '../../action/CandidateAction';
import StudentsExplorer from '../../components/StudentsExplorer/StudentsExplorer';
import constant from '../../constant';
import { Helmet } from 'react-helmet-async';
export default class studentsExplorer extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    // document.title = constant.title.StudentsExplorer
    window.scroll(0, 0)
  }





  render() {
    return (
      <React.Fragment>
<Helmet>
<title>Career Explorer - Rozgar.com</title>
<meta name="HandheldFriendly" content="True" />
<meta name="Keywords" content={ "Career-Explorer" + ", Jobs in India, Jobs in Noida, Search & Apply Job"}></meta>
<meta name="description" content={ "Career-Explorer " + " - Know more about job opportunities, employee feedback and Ratings, work culture, immediate hirings etc at Rozgar.com."} />
<link  href={"https://www.rozgar.com/career-explorer"} />
<meta name="referrer" content="no-referrer-when-downgrade" />
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>

<meta property="og:site_name" content="Rozgar.com" />
<meta property="og:title" content={ "Career Explorer - Rozgar.com"} />
<meta property="og:description" content={ "Top Companies " + " - Know more about job opportunities, employee feedback and Ratings, work culture, immediate hirings etc at Rozgar.com."} />
<meta property="og:url" content= {"https://www.rozgar.com/career-explorer"}  />
<meta property="og:image" content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png" />
<meta property="og:image:width" content="4000" />
<meta property="og:image:height" content="6000" />

<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content={ "Career Explorer - Rozgar.com"} />
<meta name="twitter:description"  content={ "Career-Explorer " + " - Know more about job opportunities, employee feedback and Ratings, work culture, immediate hirings etc at Rozgar.com."} />
<meta name="twitter:url"content= {"https://www.rozgar.com/career-explorer"} />
<meta name="twitter:image"  content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png"  />
<meta name="twitter:label1" content="Written by" />
<meta name="twitter:data1" content="Smita Nag" />
<meta name="twitter:label2" content="Filed under" />
<meta name="twitter:data2" content="Career Advice, Career Insights" />
<meta name="twitter:site" content="@rozgar_india" />

</Helmet>



        <StudentsExplorer

        />
      </React.Fragment>
    )
  }
}
