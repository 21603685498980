import React, { Component } from 'react'
import SendEnquiry from '../../components/Enquiry/SendEnquiry'
import SimpleHRWork from'../../assets/images/hr1.png'
import  EmployeeCentricWorkplace from '../../assets/images/hr2.png'
import  BusinessTransformationHRTech    from '../../assets/images/tm (1).png'
import  HRMSContentImg       from '../../assets/images/hrms-content-img.jpg'
import faqs2    from '../../assets/images/faqs2.jpg'

export default class Hrmanagementsystem extends Component {

    render() {
        return (
            <React.Fragment>
                <div id='rg-innerbannervtwo' className='rg-enquiryrvtworep rg-hed-section'>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-8">
                                <div className='page-header-section'>
                                    <div className='page-header-content'>
                                        <h4>HRMS</h4>
                                        <p>Best HR Services for Exceptional Employee Experience</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SendEnquiry />
                <main id="rg-main" className="rg-main rg-haslayout pt-0 pb-0">
                    <div className="rg-share-your-interview  rg-haslayout pt-0">
                        <section className='secrtion-bg-gray pddding-top-btm'>
                            <div className='container'>
                                <div className='row mt-5 is-active' id='slide01'>
                                    <div className='col-md-12 col-sm-4  mb-5'>
                                        <div className='content-page-box text-center'>
                                            <div className='main-head-box text-center'>
                                                <h3>HRMS</h3>
                                                <div className='feature-line'>
                                                    <span className='animate-bar'></span>
                                                </div>
                                            </div>
                                            <p>The Best Human Resource Management System For The Finest Employee Experience Rozgar.com presents the most productive and nimble cloud-based human resource management system. Its pursuit is to streamline all HR-related processes. With our HRMS, you can do a lot of things with ease, like cultivating and keeping employees, acclimating to changes, enhancing the employee experience, constructing a workforce that is concentrated on performance, and so much more.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className="rozgar-distinguishing-expertise">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">

                                        <div className='usp-top-box'>
                                            <div className='main-head-box'>
                                                <h3>Our Distinguish Expertise</h3>
                                                <div className='feature-line'>
                                                    <span className='animate-bar'></span>
                                                </div>
                                            </div>
                                            <div className='usp-main-item'>
                                                <div className='usp-item-box'>
                                                    <div className='icon-img'><img src={SimpleHRWork} /></div>
                                                    <h4>Simplified Human Resource Function.</h4>
                                                    <div className='feature-line'>
                                                        <span className='animate-bar'></span>
                                                    </div>

                                                </div>
                                                <div className='usp-item-box'>
                                                    <div className='icon-img'><img src={EmployeeCentricWorkplace} /></div>
                                                    <h4>Employee-Focused Workplace.</h4>
                                                    <div className='feature-line'>
                                                        <span className='animate-bar delay-1-0s'></span>
                                                    </div>

                                                </div>
                                                <div className='usp-item-box'>
                                                    <div className='icon-img'><img src={BusinessTransformationHRTech} /></div>
                                                    <h4>Growing Businesses Through Advanced Technologies.</h4>
                                                    <div className='feature-line'>
                                                        <span className='animate-bar delay-2-0s'></span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <section className='secrtion-bg-gray pddding-top-btm'>
                            <div className='container'>
                                <div className='row mt-5 is-active' id='slide01'>
                                    <div className='col-md-5 col-sm-4 mb-5'>
                                        <div className='one-side-image-bx'>
                                            <img src={HRMSContentImg} className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-md-7 col-sm-4  mb-5'>
                                        <div className='content-page-box hrms-content-bx'>
                                            <div className='main-head-box'>
                                                <h3>Services We Offer</h3>
                                                <div className='feature-line'>
                                                    <span className='animate-bar'></span>
                                                </div>
                                            </div>
                                            <p>You can relish the advantages of advanced automation in Human Resource processes and operations with the award-winning HRMS from Rozgar.com. The empowerment of the organisation and employee connections are ensured by our HRMS. It also plays a crucial role in the company's efficient management of the employee lifecycle.</p>

                                            <h5>1. Enhanced Function</h5>
                                            <p>The work of HRMS is to improve the hiring and recruitment functions and experience for both employees and employers. Our HRMS concentrates on areas of the Applicants' Tracking System (ATS), Employee On-Boarding, Referral Processing and Vendor Management Systems.</p>
                                            
                                            <h5>2. HR Functions</h5>
                                            <p>HRMS software helps in the effective management of employee performance. All the tools required for a seamless HR functioning like Leave & Attendance, Business Communication, Help Desk, Asset Allocation and Exit Process can be done through this service. </p>

                                            <h5>3. Enhanced Productivity</h5>
                                            <p>Through HRMS software an employee’s performance can easily be tracked. You can have control over the management and efficiency of your employees through GeoTag and GeoFence support.</p>
                                            <p>Apart from these, HRMS also provides functions like Real-time Tracking, Video & Voice Calling, Enterprise groups, Analytics & Insights, and Performance Management.</p>

                                            <h5>4. Payroll Management</h5>
                                            <p>Our software for a cloud-based HR management system is well-known for its adaptability and adaptability in handling all salary-related work and issues. It provides 100 per cent accuracy and incorporates the leave and attendance management system to create HR work easier and fulfil the requirements of employees.</p>
                                            <p>Payroll, expenses, repayment, loans and advances, compliance, and insurance are among the numerous HRMS functions.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className='cmt-row about-section clearfix'>
                            <div className='container'>
                                <div className='row align-items-center'>
                                    <div className='col-xl-12 col-md-12'>
                                        <div className='section-title style2 res-991-pb-15 text-center'>
                                            <div className='title-header'>
                                                <h4 className='title'>What Is The Need For HRMS?</h4>
                                            </div>
                                            <div className='feature-line'>
                                                <span className='animate-bar'></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb_15'>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-one'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3>Data Security</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>To enhance employee background and organisational workflow, the human resource management system furnishes centralised and converged management of all critical employee functions and data.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-two'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3>Systematic HR Process</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>The HRMS at Rozgar.com is a well-designed technology that allows for the uncomplicated configuration of numerous policies using pre-existing templates to ensure maximum functionality. Additionally, it is very easy to automate any process for the highest efficiency.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-three'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3>Transparent Employee Analytics</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>Our HRMS software is completely reliable to keep a detailed track and transparent insights on all of the employee’s performance. Here workforce cost, employee retention rates and so many other things could also be done.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-6 offset-4'>
                                        <div className='featured-icon-box icon-align-before-content style4 benefits-box height-box'>
                                            <div className='featured-content'>
                                                <div className='cmt-icon cmt-icon_element-fill cmt-icon_element-style-rounded cmt-icon_element-color-white cmt-icon_element-size-lg'>
                                                    <div className='cmt-num'><span className='number number-four'></span></div>
                                                </div>
                                                <div className='featured-title'>
                                                    <h3>Mobile Friendly</h3>
                                                </div>
                                                <div className='featured-desc'>
                                                    <p>HRMS software by Rozgar.com is mobile-friendly software that offers the top benefit of self-service, fully-functional HR solutions and effective HR practices.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        
                        <section className='pddding-top-btm bg-white'>
                            <div className='container'>
                                <div className='row mt-5 ' id='slide01'>
                                    <div className='col-md-5 col-sm-4 mb-5'>
                                        <div className='one-side-image-bx'>
                                            <img src={faqs2} style={{maxWidth:'350px'}} />
                                        </div>
                                    </div>
                                    <div className='col-md-7 col-sm-6  mb-5'>
                                        <div className='content-page-box'>
                                            <div className='main-head-box'>
                                                <h3>FAQs</h3>
                                                <div className='feature-line'>
                                                    <span className='animate-bar'></span>
                                                </div>
                                            </div>
                                            <div className='faqscontent'>
                                                <div>
                                                    <input type='checkbox' id='question1' name='q' className='questions' />
                                                    <div className='plus'>+</div>
                                                    <label for='question1' className='question'>
                                                    What do human resources mean?
                                                    </label>
                                                    <div class="answers">
                                                        <p>Human resources or popularly known as HR is a division of the business that is responsible for searching, sourcing, recruiting, retaining and training employees.</p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <input type='checkbox' id='question2' name='q' className='questions' />
                                                    <div className='plus'>+</div>
                                                    <label for='question2' className='question'>
                                                    What are the functions of HRMS?
                                                    </label>
                                                    <div class="answers">
                                                        <p>HRMS's job is to offer extended support for HR operations like payroll management, employee data management, recruitment, employee training, compensation and more.
                                                        </p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <input type='checkbox' id='question3' name='q' className='questions' />
                                                    <div className='plus'>+</div>
                                                    <label for='question3' className='question'>
                                                    What does HRMS stand for?
                                                    </label>
                                                    <div class="answers">
                                                        <p>HRMS stands for Human Resource Management System, a software designed by Rozgar.com.</p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <input type='checkbox' id='question4' name='q' className='questions' />
                                                    <div className='plus'>+</div>
                                                    <label for='question4' className='question'>
                                                    Which is better HRIS or HRMS?
                                                    </label>
                                                    <div class="answers">
                                                        <p>Both software is quite major in the field of HR, however, HRIS is basic concentrating only on the fundamental details, whereas, HRMS focuses on the meticulous and complicated version of HR functioning.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </main>
            </React.Fragment>
        )
    }
}
