import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import constant from '../../constant'
import pic04 from '../../assets/images/adds-05.jpg'

export default class Coupon extends Component {
  render() {
    return (
     <React.Fragment>
      <main id="rg-main" className="rg-main rg-haslayout pt-0">
  <div className="rg-sectionspace rg-haslayout pt-0">
    <div className="rozgar-jobbylocsearch pt-2 pb-2">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <div className="rg-title">
              <h3 className="text-white">Coupons for You</h3>
            </div>
          </div>
          <div className="col-12">
            <div className="numofsavejob">
              <span className="font-20">
                  Your Rewards & Coupons 
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="rg-haslayout mt-4">
      <div className="container">
        <div className="row">
          <div id="rg-threecolumns" className="rg-threecolumns">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 float-left">
              <div className="rg-featuredjobs rg-featuredjobsvtwo rg-featuredjobsvthree">
                <div className="row">
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="rg-rewardCoupon-item">
                           <div className='coupon-img-bx'>
                               <img className='coupon-img' src={pic04} />
                               <div className='coupon-company-img'>
                                   
                               </div>
                           </div>
                          
                        </div>
                        
                       
                      </div>
                      <nav className="rg-pagination">
                        <ul>
                          <ul className="pagination">
                            <li className="page-item disabled">
                              <a
                                className="page-link"
                                href="#"
                                aria-label="Go to first page"
                              >
                                «
                              </a>
                            </li>
                            <li className="page-item disabled">
                              <a
                                className="page-link"
                                href="#"
                                aria-label="Go to previous page"
                              >
                                ⟨
                              </a>
                            </li>
                            <li className="page-item active">
                              <a
                                className="page-link undefined"
                                href="#"
                                aria-label="Go to page number 1"
                              >
                                1
                              </a>
                            </li>
                            <li className="page-item">
                              <a
                                className="page-link"
                                href="#"
                                aria-label="Go to page number 2"
                              >
                                2
                              </a>
                            </li>
                            <li className="page-item">
                              <a
                                className="page-link"
                                href="#"
                                aria-label="Go to next page"
                              >
                                ⟩
                              </a>
                            </li>
                            <li className="page-item">
                              <a
                                className="page-link"
                                href="#"
                                aria-label="Go to last page"
                              >
                                »
                              </a>
                            </li>
                          </ul>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="savejobs-aside">
                      <ul>
                        <li>
                          <a href="/recommended-jobs">
                            <i className="ti-bookmark" />
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                              Recommended Job
                            </span>{" "}
                          </a>
                        </li>
                        <li>
                          <a href="/featured-jobs">
                            <i className="lnr lnr-bullhorn" />
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                              Featured Job
                            </span>{" "}
                          </a>
                        </li>
                        <li>
                          <a href="/interview-questions">
                            <i className="ti-crown" />
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                              Interview Question
                            </span>{" "}
                          </a>
                        </li>
                        <li>
                          <a href="/top-companies">
                            <i className="lnr lnr-apartment" />{" "}
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                              Top Companies
                            </span>{" "}
                          </a>
                        </li>
                        <li>
                          <a href="/jobs/fresher">
                            <i className="lnr lnr-graduation-hat" />
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                              Freshers Jobs
                            </span>{" "}
                          </a>
                        </li>
                        <li>
                          <a href="/browse-jobs">
                            <i className="lnr lnr-checkmark-circle" />
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                              All Jobs
                            </span>{" "}
                          </a>
                        </li>
                        <li>
                          <a href="/international-jobs">
                            <i className="fa fa-plane" />
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                              International Jobs
                            </span>{" "}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

     </React.Fragment>
    )
  }
}
