import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async';
import Pay from '../../components/EmployerServices/Payrollautomation'
import { capFirstLetterInSentence } from '../../utils'



export default class Payrollautomation extends Component {
    render() {

        return (
            <React.Fragment>
                   <Helmet >


<title >Payroll Automation | Rozgar.com</title>
<meta name="HandheldFriendly" content="True" />
<meta name="Keywords" content={ "Payroll-Automation" + ", Jobs in India, Jobs in Noida, Search & Apply Job"}></meta>
<meta name="description" content={ "Payroll-Automation" + "-3rd Party Payroll Outsourcing Employee Management Solutions Simplifying payroll management for your organisation"} />
<link   href={"https://www.rozgar.com/payroll-automation"} />
<meta name="referrer" content="no-referrer-when-downgrade" />
<meta name="viewport" content="width=device-width, initial-scale=1"/>

<meta property="og:site_name" content="Rozgar.com" />
<meta property="og:title"content={ "Payroll Automation | Rozgar.com"} />
<meta property="og:description" content={ "Payroll-Automation" + "-3rd Party Payroll Outsourcing Employee Management Solutions Simplifying payroll management for your organisation"} />
<meta property="og:url" content= {"https://www.rozgar.com/payroll-automation"}  />
<meta property="og:image" content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png" />
<meta property="og:image:width" content="4000" />
<meta property="og:image:height" content="6000" />

<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content={"Payroll Automation | Rozgar.com"} />
<meta name="twitter:description"  content={ "Payroll-Automation" + "-3rd Party Payroll Outsourcing Employee Management Solutions Simplifying payroll management for your organisation"} />
<meta name="twitter:url"content= {"https://www.rozgar.com/payroll-automation"} />
<meta name="twitter:image"  content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png"  />
<meta name="twitter:label1" content="Written by" />
<meta name="twitter:data1" content="Smita Nag" />
<meta name="twitter:label2" content="Filed under" />
<meta name="twitter:data2" content="Career Advice, Career Insights" />
<meta name="twitter:site" content="@rozgar_india" />
</Helmet>
                <Pay />
            </React.Fragment>
        )
    }
}
