import React, { Component } from 'react'
import constant from '../../constant'
import JavaFullStack from '../../assets/images/JavaFullStack.jpg'
import pythonfullStack  from '../../assets/images/pythonfull.jpg'
import softwareTesting from '../../assets/images/software-testing.jpg'
import { courseList } from '../../action/CandidateAction'

export default class UpgradeSkill extends Component {
    constructor() {
        super()
        this.state = {
            data: undefined,
          
        }
    }
      componentDidMount() {
        courseList().then((res) => {
            if (res.status) {
                this.setState({ data: res.result })
            }
        })
       
    }
  render() {
    return (
        <main id="rg-main" className="rg-main rg-haslayout pt-0">
      
        <section className='main-section-box upgrade-sec'>
            <div className='container'>
                 <div className='row'>
                    <div className='col-md-12'>
                         <div className='skills-had-update'>
                            <h4>Upgrade Your Skills</h4>
                            <p>Find courses that are best for your career and improve your skills...</p>
                         </div>
                         

                     </div>
                  </div>   

                <div className='row'>
                  
                    {this.state.data !== undefined && this.state.data.map((item, index) => {
                            console.log(item,"item");
                                                                        return (
                                                                            <div className='col-md-4'>
                                                                            <a target="_blank" href={constant.component.courseDetailById.url.replace(':url/:COURSE_ID', item.URL + '/' + item.COURSE_ID)}>
                                                                            <div className='Upgrade-Skill-box'>
                                                                                <div className='upgrade-skills-img'>
                                                                                   <img src={item.COURSE_IMAGE} /> 
                                                                                </div>
                                                                                <div class="skills-text-box"><h5>{item.COURSE_TITLE}</h5></div>
                                                                            </div>
                                                                            </a>
                                                                            </div>

                                                                        )
                                                                    })
                                                                    }
                       

                   
                    {/* <div className='col-md-4'>
                    <a target='_blank' href='https://www.rozgar.com/course-detail/python-full-stack/2'>
                        <div className='Upgrade-Skill-box'>
                            <div className='upgrade-skills-img'>
                               <img src={pythonfullStack} /> 
                            </div>
                            <div class="skills-text-box"><h5>Python Full Stack </h5></div>
                        </div>
                        </a>
                    </div>
                    <div className='col-md-4'>
                      <a target='_blank' href='https://www.rozgar.com/course-detail/software-testing/3'>
                        <div className='Upgrade-Skill-box'>
                            <div className='upgrade-skills-img'>
                               <img src={softwareTesting} /> 
                            </div>
                            <div class="skills-text-box"><h5>Software Testing </h5></div>
                        </div>
                        </a>

                    </div> */}
                   
                </div>
            </div>
        </section>
     
    </main>
    )
  }
}


