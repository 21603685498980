import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async';
import Hr from '../../components/EmployerServices/Hrmanagementsystem'
import constant from '../../constant'
import { capFirstLetterInSentence } from '../../utils';

export default class Hrmanagementsystem extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
      }
      componentDidMount() {
        // document.title = constant.title.hrmanagementsystem
       
      }
    render() {
        return (

            <React.Fragment>
                 <Helmet >


                 <title >Human Resource Management System - Rozgar.com</title>
                 <meta name="HandheldFriendly" content="True" />
                 <meta name="Keywords" content={ "Human-Resource-Management-System" + ", Jobs in India, Jobs in Noida, Search & Apply Job"}></meta>
                 <meta name="description" content={ "Human-Resource-Management-System" + "human resource management system ensures agile and effective HR management. Simplify all the HR functions, retain talented employees, nurture employees, make quick adaptation to changes, enhance employee experience, build a performance-oriented workforce, etc., without any hassles or stress with our HRMS."} />
                 <link  href={"https://www.rozgar.com/hrms"} />
                 <meta name="referrer" content="no-referrer-when-downgrade" />
                 <meta name="viewport" content="width=device-width, initial-scale=1"/>
                 
                 <meta property="og:site_name" content="Rozgar.com" />
                 <meta property="og:title"content={ "Human Resource Management System - Rozgar.com"} />
                 <meta property="og:description" content={ "Human-Resource-Management-System" + "human resource management system ensures agile and effective HR management. Simplify all the HR functions, retain talented employees, nurture employees, make quick adaptation to changes, enhance employee experience, build a performance-oriented workforce, etc., without any hassles or stress with our HRMS."} />
                 <meta property="og:url" content= {"https://www.rozgar.com/hrms"}  />
                 <meta property="og:image" content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png" />
                 <meta property="og:image:width" content="4000" />
                 <meta property="og:image:height" content="6000" />
                 
                 <meta name="twitter:card" content="summary_large_image" />
                 <meta name="twitter:title" content={"Human-Resource-Management-System"} />
                 <meta name="twitter:description"  content={ "Human-Resource-Management-System" + "human resource management system ensures agile and effective HR management. Simplify all the HR functions, retain talented employees, nurture employees, make quick adaptation to changes, enhance employee experience, build a performance-oriented workforce, etc., without any hassles or stress with our HRMS."} />
                 <meta name="twitter:url"content= {"https://www.rozgar.com/hrms"} />
                 <meta name="twitter:image"  content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png"  />
                 <meta name="twitter:label1" content="Written by" />
                 <meta name="twitter:data1" content="Smita Nag" />
                 <meta name="twitter:label2" content="Filed under" />
                 <meta name="twitter:data2" content="Career Advice, Career Insights" />
                 <meta name="twitter:site" content="@rozgar_india" />
</Helmet>
                <Hr />
            </React.Fragment>
        )
    }
}
