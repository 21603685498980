import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async';
import Start from '../../components/EmployerServices/Startupincubation'
import constant from '../../constant'
import { capFirstLetterInSentence } from '../../utils'

export default class Startupincubation extends Component {
    render() {
        return (

            <React.Fragment>
                                <Helmet >


<title  >Startup Incubation | Rozgar.com</title> 
<meta name="HandheldFriendly" content="True" />
<meta name="Keywords" content={ "Startup-Incubation" + ", Jobs in India, Jobs in Noida, Search & Apply Job"}></meta>
<meta name="description" content={ "Startup-Incubation" + " - Our organization has vast experience in partnering with best-in-class global organizations to manage regulatory compliances in setting up a business and its continuous operations. We are committed to ensuring the privacy and confidentiality of our clients. Our team follows strict standards to guard the information to ensure that all information provided to us is safe and secure."} />
<link  href={"https://www.rozgar.com/startup-incubation"} />
<meta name="referrer" content="no-referrer-when-downgrade" />
<meta name="viewport" content="width=device-width, initial-scale=1"/>


<meta property="og:site_name" content="Rozgar.com" />
<meta property="og:title"content={ "Startup Incubation | Rozgar.com"} />
<meta property="og:description" content={ "Startup-Incubation" + " - Our organization has vast experience in partnering with best-in-class global organizations to manage regulatory compliances in setting up a business and its continuous operations. We are committed to ensuring the privacy and confidentiality of our clients. Our team follows strict standards to guard the information to ensure that all information provided to us is safe and secure."} />
<meta property="og:url" content= {"https://www.rozgar.com/startup-incubation"}  />
<meta property="og:image" content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png" />
<meta property="og:image:width" content="4000" />
<meta property="og:image:height" content="6000" />

<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content={"Startup Incubation | Rozgar.com"} />
<meta name="twitter:description"  content={ "Startup-Incubation" + " - Our organization has vast experience in partnering with best-in-class global organizations to manage regulatory compliances in setting up a business and its continuous operations. We are committed to ensuring the privacy and confidentiality of our clients. Our team follows strict standards to guard the information to ensure that all information provided to us is safe and secure."} />
<meta name="twitter:url"content= {"https://www.rozgar.com/startup-incubation"} />
<meta name="twitter:image"  content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png"  />
<meta name="twitter:label1" content="Written by" />
<meta name="twitter:data1" content="Smita Nag" />
<meta name="twitter:label2" content="Filed under" />
<meta name="twitter:data2" content="Career Advice, Career Insights" />
<meta name="twitter:site" content="@rozgar_india" />
</Helmet>

                <Start />


            </React.Fragment>
        )
    }
}
