import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async';
import StudyInAustralia from '../../components/StudyAbroad/StudyInAustralia';
import constant from '../../constant';
import { capFirstLetterInSentence } from '../../utils';

export default class studyInAustralia extends Component {
    constructor(props){
        super(props);
        this.state={

        }
    }
    componentDidMount(){
        document.title = constant.title.studyInAustralia
    }
  render() {
    return (
      <React.Fragment>
               <Helmet >


<title >Study in Australia - Rozgar.com</title>
<meta name="HandheldFriendly" content="True" />
<meta name="Keywords" content={ "Study in Australia " + ", Jobs in India, Jobs in Noida, Search & Apply Job"}/>
<meta name="description" content={ "Study in Australia " + " - Australia is a land of wonders- from the fantastically diverse wildlife to the astonishingly beautiful places, Australia is a country full of potential. Australia also has a booming economy- it is the 14th largest economy in the world. It is also a welcoming place, with immigrants accounting for 30% of the population which makes Australia the country with the highest proportion of immigrants among those with a population of over 10 million. India- Australia relations are also very good, and like India, Australia is also a democracy. In fact, it is the eighth-highest ranked democracy globally."} />
<link  href={"https://www.rozgar.com/study-in-australia"} />
<meta name="referrer" content="no-referrer-when-downgrade" />
<meta name="viewport" content="width=device-width, initial-scale=1"/>


<meta property="og:site_name" content="Rozgar.com" />
<meta property="og:title"content={ "Study in Australia - Rozgar.com"} />
<meta property="og:description" content={ "Study in Australia" + " - Australia is a land of wonders- from the fantastically diverse wildlife to the astonishingly beautiful places, Australia is a country full of potential. Australia also has a booming economy- it is the 14th largest economy in the world. It is also a welcoming place, with immigrants accounting for 30% of the population which makes Australia the country with the highest proportion of immigrants among those with a population of over 10 million. India- Australia relations are also very good, and like India, Australia is also a democracy. In fact, it is the eighth-highest ranked democracy globally."} />
<meta property="og:url" content= {"https://www.rozgar.com/study-in-australia"}  />
<meta property="og:image" content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png" />
<meta property="og:image:width" content="4000" />
<meta property="og:image:height" content="6000" />

<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content={"Study in Australia - Rozgar.com"} />
<meta name="twitter:description"  content={ "Study in Australia" + " - Australia is a land of wonders- from the fantastically diverse wildlife to the astonishingly beautiful places, Australia is a country full of potential. Australia also has a booming economy- it is the 14th largest economy in the world. It is also a welcoming place, with immigrants accounting for 30% of the population which makes Australia the country with the highest proportion of immigrants among those with a population of over 10 million. India- Australia relations are also very good, and like India, Australia is also a democracy. In fact, it is the eighth-highest ranked democracy globally."} />
<meta name="twitter:url"content= {"https://www.rozgar.com/study-in-australia"} />
<meta name="twitter:image"  content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png"  />
<meta name="twitter:label1" content="Written by" />
<meta name="twitter:data1" content="Smita Nag" />
<meta name="twitter:label2" content="Filed under" />
<meta name="twitter:data2" content="Career Advice, Career Insights" />
<meta name="twitter:site" content="@rozgar_india" />
</Helmet>
        <StudyInAustralia/>
      </React.Fragment>
    )
  }
}
