import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import resume01 from '../../../assets/img/demos/demo-1.png';
import resume02 from '../../../assets/img/demos/demo-2.png';
import resume03 from '../../../assets/img/demos/demo-3.png';
import resume04 from '../../../assets/img/demos/demo-4.png';
import resume05 from '../../../assets/img/demos/demo-5.png';
import resume06 from '../../../assets/img/demos/demo-6.png';
import resume07 from '../../../assets/img/demos/demo-7.png';

import servicepic01 from '../../../assets/img/icons/f1.png';
import servicepic02 from '../../../assets/img/icons/f2.png';
import servicepic03 from '../../../assets/img/icons/f3.png';
import servicepic04 from '../../../assets/img/icons/f4.png';
import servicepic05 from '../../../assets/img/icons/f5.png';
import servicepic06 from '../../../assets/img/icons/f6.png';

import dollarsys from '../../../assets/img/svg/img-dollar.svg';
import bannerrm from '../../../assets/img/core-img/banner2.png';

import easyonline from '../../../assets/img/icons/easyonline.png';
import stepbystep from '../../../assets/img/icons/stepbystep.png';
import recruitera from '../../../assets/img/icons/recruitera.png';

import checkright from '../../../assets/img/icons/check.png';
import resumecv from '../../../assets/img/core-img/cv.png';
import custmoricon from '../../../assets/img/core-img/custom.png';

import testimonal01 from '../../../assets/img/test-img/1.jpg';
import testimonal02 from '../../../assets/img/test-img/2.jpg';
import testimonal03 from '../../../assets/img/test-img/3.jpg';
import constant from '../../../constant';
import { getStorage } from '../../../utils';



export default class ResumeChooseTemplate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            candidateID: getStorage(constant.keys.cd) ? getStorage(constant.keys.cd) : '',
        }
    }

  render() {
    const lpFeaturedJobs = {
        dots: false, infinite: true, speed: 500, autoplay: false,
        slidesToShow: 3, slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    dots: false,
                    arrows: false
                }
            },

            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false
                }
            }
        ]

    };
    const { candidateID } = this.state
    return (
      <React.Fragment>
        <section className="demo section-padding-50 pb-10 ring-bg" id='createtemplate'>
                      <div className='container'>
                            <div className="section-heading text-center" style={{marginTop:"100px"}}>
                                <div className="dream-dots justify-content-center">
                                    <span></span><span></span><span></span><span></span><span></span><span></span><span></span>
                                </div>
                                <h2 className="bold">Our Creative Templates</h2>
                                <p>Pick a resume template, fill it out, and format. Create a professional resume in a few clicks. Just choose one of 18+ resume templates below, add ready-made content, download, and get the job.</p>
                            </div> 

                            <div className='resume-slider-section' style={{marginBottom:"50px"}}>
                            <Slider {...lpFeaturedJobs} > 
                                    <div className="col slider-item">
                                        <div className="demo-item">
                                            <Link href=""><img src={resume01} alt="demo" className="img-responsive" /></Link>
                                            <div className="preview-btn-wrapper text-center">
                                                {/* <a href={constant.component.TemplatePreview.url} className="preview-demo">See template <i className="fa fa-long-arrow-right"></i></a> */}
                                                <a href={constant.component.ResumeViewOne.url} className="preview-demo v2" style={{ padding: "8px 10px" }}>Use template <i className="fa fa-long-arrow-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col slider-item">
                                        <div className="demo-item">
                                            <a href=""><img src={resume02} alt="demo" className="img-responsive" /></a>
                                            <div className="preview-btn-wrapper text-center">
                                                {/* <a href={constant.component.TemplatePreview01.url} className="preview-demo">See template <i className="fa fa-long-arrow-right"></i></a> */}
                                                <a href={constant.component.ResumeViewTwo.url} className="preview-demo v2" style={{ padding: "8px 10px" }}>Use template <i className="fa fa-long-arrow-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col slider-item">
                                        <div className="demo-item">
                                            <a href=""><img src={resume03} alt="demo" className="img-responsive" /></a>
                                            <div className="preview-btn-wrapper text-center">
                                                {/* <a href={constant.component.TemplatePreview02.url} className="preview-demo">See template <i className="fa fa-long-arrow-right"></i></a> */}
                                                <a href={constant.component.ResumeViewThree.url} className="preview-demo v2" style={{ padding: "8px 10px" }}>Use template <i className="fa fa-long-arrow-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col slider-item">
                                            <div className="demo-item">
                                                <Link href=""><img src={resume04} alt="demo" className="img-responsive" /></Link>
                                                <div className="preview-btn-wrapper text-center">
                                                    {/* <a href={constant.component.TemplatePreview04.url} className="preview-demo">See template <i className="fa fa-long-arrow-right"></i></a> */}
                                                    <a href={constant.component.resumeTemplate2.url} className="preview-demo v2" style={{ padding: "8px 10px" }}>Use template <i className="fa fa-long-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col slider-item">
                                            <div className="demo-item">
                                                <a href=""><img src={resume05} alt="demo" className="img-responsive" /></a>
                                                <div className="preview-btn-wrapper text-center">
                                                    {/* <a href={constant.component.TemplatePreview05.url} className="preview-demo">See template <i className="fa fa-long-arrow-right"></i></a> */}
                                                    <a href={constant.component.ResumeTemplateView03.url} className="preview-demo v2" style={{ padding: "8px 10px" }}>Use template <i className="fa fa-long-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col slider-item">
                                            <div className="demo-item">
                                                <a href=""><img src={resume06} alt="demo" className="img-responsive" /></a>
                                                <div className="preview-btn-wrapper text-center">
                                                    {/* <a href={constant.component.TemplatePreview06.url} className="preview-demo">See template <i className="fa fa-long-arrow-right"></i></a> */}
                                                    <a href={constant.component.ResumeTemplateView02.url} className="preview-demo v2" style={{ padding: "8px 10px" }}>Use template <i className="fa fa-long-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col slider-item">
                                            <div className="demo-item">
                                                <Link href=""><img src={resume07} alt="demo" className="img-responsive" /></Link>
                                                <div className="preview-btn-wrapper text-center">
                                                    {/* <a href={constant.component.TemplatePreview07.url} className="preview-demo">See template <i className="fa fa-long-arrow-right"></i></a> */}
                                                    <a href={constant.component.resumeTemplate1.url} className="preview-demo v2" style={{ padding: "8px 10px" }}>Use template <i className="fa fa-long-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>    
                            
                            </div> 
                      </div>
                 </section>
      </React.Fragment>
    )
  }
}
