import React, { Component } from 'react'
import Signin from '../../components/signin/signin'
import constant from '../../constant';
import { getStorage, setStorage } from '../../utils';
import Signinwithotp from '../../components/signin/signinwithotp';
import swal from 'sweetalert'
import { candidateLogin, googleLoginAuth } from '../../action/CandidateAction'

export default class signin extends Component {
    constructor(props) {
        super(props);
        this.state = {
          tab: 'EMAIL',
          loginDatas:null,
          showLoader:false,
          detail:getStorage(constant.keys.cd),
          data:localStorage.getItem('JobUrl')
        }
    }
    componentDidMount() {
        document.title = constant.title.Signin
        window.scrollTo(0, 0)
        let auth = getStorage(constant.keys.ctoken)
        let cd=getStorage(constant.keys.cd)
        if (auth && cd) {
          this.props.history.push(constant.component.editProfile.url)
        }
    }
    changeTab = (tab) => {
      this.setState({ tab: tab })
    }
    onSubmit = (model) => {
     const Url = this.state.data
      this.setState({showLoader:true})
      candidateLogin(model).then((res) => {
        this.setState({showLoader:false})
          if (res.status) {
            this.setState({showLoader:false})
            swal({
              icon: "success",
              text: "You have Successfully Logged In",
              timer: 1000,
              showCancelButton: false,
              showConfirmButton: false
            });
               this.props.history.push(constant.component.editProfile.url)
          } else {
            this.setState({showLoader:false})
            swal({
              icon: "error",
              text: res.error,
              timer: 2000,
              showCancelButton: false,
              showConfirmButton: false,
            });
          }
        });
  }

  googleLoginHandler=(googleData)=>{
    googleLoginAuth(googleData).then((res) => {
      if (res.status) {
        this.setState({
          loginDatas:res
        })
        this.props.history.push(constant.component.editProfile.url)
      }else {
        swal({
          icon: "error",
          text: res.error,
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    });
  }

    render() {
      const { tab } = this.state;
        return (
            <React.Fragment>
                {tab === 'EMAIL' && <Signin
          changeTab={(tab) => this.changeTab(tab)}
          leftBar={this.props.leftBar}
          onSubmit={this.onSubmit}
          googleLoginHandler={this.googleLoginHandler}
          loginData={this.props.loginDatas}
          showLoader={this.state.showLoader}
        />}

 {tab === 'PHONE' && <Signinwithotp
          changeTab={(tab) => this.changeTab(tab)}
          leftBar={this.props.leftBar}
          onSubmit={this.onSubmit}
          googleLoginHandler={this.googleLoginHandler}
          loginData={this.props.loginDatas}
          showLoader={this.state.showLoader}
        />}
            </React.Fragment>
        )
    }
   
}
