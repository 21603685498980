import React, { Component } from 'react'
import { CompanyLists, DepartmentLists, SkillsForInterview, totalCount } from '../../action/ShareInterviewAction';
import ShareAnInterview from '../../components/InterviewQuestions/ShareAnInterview';
import constant from '../../constant';

export default class shareAnInterview extends Component {
    constructor(props){
        super(props);
        this.state = {
          skill_list:undefined,
          departments:undefined,
          totalCount:undefined
        }
    }
 componentDidMount(){
    document.title = constant.title.ShareAnInterview
    this.SkillsList()
    this.CompanyList()
    this.DepartmentLists()
    this.Counting()
 }
 Counting = () => {
  totalCount().then((res)=>{
    this.setState({totalCount:res.result[0]}) 
  }).catch(err=>alert(err))
 }
 SkillsList = () =>{
  SkillsForInterview().then((res)=>{
    this.setState({skill_list:res.result})
  }).catch(err=>alert(err))
 }

 CompanyList = () => {
  CompanyLists().then((res)=>{
    this.setState({Company_list:res.result})
  }).catch(err=>alert(err))
 }
 DepartmentLists = () => {
  DepartmentLists().then((res)=>{
    this.setState({departments:res.result})
  })
 }

  render() {
    const {skill_list,Company_list,departments,totalCount} = this.state
    return (
      <React.Fragment>
        <ShareAnInterview 
        skill_list={skill_list}
        Company_list={Company_list}
        departments={departments}
        totalCount={totalCount}
        />
      </React.Fragment>
    )
  }
}
