import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async';
import PersonalRecruiter from '../../components/PersonalRecruiter/PersonalRecruiter'
import constant from '../../constant'
import { capFirstLetterInSentence } from '../../utils';
export default class personalRecruiter extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }
    componentDidMount(){
      document.title = constant.title.PersonalRecruiter
      window.scrollTo(0, 0)
    }
    
  render() {
    return (
      <React.Fragment>
            <Helmet >


<title >Personal Recruiter | Rozgar.com </title>
<meta name="HandheldFriendly" content="True" />
<meta name="Keywords" content={"Personal- Recruiter" + " , Jobs in India, Jobs in Noida, Search & Apply Job"}></meta>
<meta name="description" content={"Personal- Recruiter" + "- Search, find and apply to job opportunities at Rozgar.com - India's leading Job Portal. Bring your perception, imagination and healthy neglect for the impossible. For query call us at +91-9311744658 or email us at contact@rozgar.com"} />
<link rel="canonical" href={"https://www.rozgar.com/PersonalRecruiter"} />
<meta name="referrer" content="no-referrer-when-downgrade" />
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>

<meta property="og:site_name" content="Rozgar.com" />
<meta property="og:title" content={"Personal- Recruiter | Rozgar.com"} />
<meta property="og:description" content={"Personal- Recruiter" + "- Search, find and apply to job opportunities at Rozgar.com - India's leading Job Portal. Bring your perception, imagination and healthy neglect for the impossible. For query call us at +91-9311744658 or email us at contact@rozgar.com"} />
<meta property="og:url" content={"https://www.rozgar.com/PersonalRecruiter"} />
<meta property="og:image" content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png" />

<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content={"Personal- Recruiter | Rozgar.com"} />
<meta name="twitter:description" content={"Personal- Recruiter" + "- Search, find and apply to job opportunities at Rozgar.com - India's leading Job Portal. Bring your perception, imagination and healthy neglect for the impossible. For query call us at +91-9311744658 or email us at contact@rozgar.com"} />
<meta name="twitter:url" content={"https://www.rozgar.com/PersonalRecruiter"} />
<meta name="twitter:image" content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png" />
<meta name="twitter:label1" content="Written by" />
<meta name="twitter:data1" content="Smita Nag" />
<meta name="twitter:label2" content="Filed under" />
<meta name="twitter:data2" content="Career Advice, Career Insights" />
<meta name="twitter:site" content="@rozgar_india" />
<meta property="og:image:width" content="4000" />
<meta property="og:image:height" content="6000" />
</Helmet>
        <PersonalRecruiter/>
      </React.Fragment>
    )
  }
}
