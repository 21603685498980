import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async';
import Page from '../../components/StudyAbroad/studyAbroad';
import constant from '../../constant';
import { capFirstLetterInSentence } from '../../utils';

export default class StudyAbroad extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount() {
    // document.title = constant.title.StudyAbroad
    window.scroll(0, 0)
  }

  render() {
    return (
      <React.Fragment>
                <Helmet >


                <title >Study Abroad | Rozgar.com</title>
                <meta name="HandheldFriendly" content="True" />
                <meta name="Keywords" content={ "Study Abroad " + ", Jobs in India, Jobs in Noida, Search & Apply Job"}></meta>
                <meta name="description" content={ "Study-Abroad" + " -There is nothing more important than a good education in a world like ours where the competition is cutthroat and careers can be made or broken by just a bad choice"} />
                <link  href={"https://www.rozgar.com/study-abroad"} />
                <meta name="referrer" content="no-referrer-when-downgrade" />
                <meta name="viewport" content="width=device-width, initial-scale=1"/>

                
                <meta property="og:site_name" content="Rozgar.com" />
                <meta property="og:title"content={"Study Abroad | Rozgar.com"} />
                <meta property="og:description" content={ "Study-Abroad" + "  -There is nothing more important than a good education in a world like ours where the competition is cutthroat and careers can be made or broken by just a bad choice"} />
                <meta property="og:url" content= {"https://www.rozgar.com/study-abroad"}  />
                <meta property="og:image" content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png" />
                <meta property="og:image:width" content="4000" />
                <meta property="og:image:height" content="6000" />
                
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={"Study Abroad | Rozgar.com"} />
                <meta name="twitter:description"  content={"Study-Abroad" + " - There is nothing more important than a good education in a world like ours where the competition is cutthroat and careers can be made or broken by just a bad choice"} />
                <meta name="twitter:url"content= {"https://www.rozgar.com/study-abroad"} />
                <meta name="twitter:image"  content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png"  />
                <meta name="twitter:label1" content="Written by" />
                <meta name="twitter:data1" content="Smita Nag" />
                <meta name="twitter:label2" content="Filed under" />
                <meta name="twitter:data2" content="Career Advice, Career Insights" />
                <meta name="twitter:site" content="@rozgar_india" />
</Helmet>
        <Page />
      </React.Fragment>
    )
  }
}
