import React, { Component } from 'react'
import constant from '../../constant';
import StudyInSingapores from '../../components/StudyAbroad/StudyInSingapore';
import { Helmet } from 'react-helmet-async';

export default class StudyInSingapore extends Component {
    constructor(props){
        super(props);
        this.state={

        }
    }
    componentDidMount(){
        document.title = constant.title.studyInSingapore
    }
  render() {
    return (
      <React.Fragment>
            <Helmet >


<title >Study In Singapore - Rozgar.com</title>
<meta name="HandheldFriendly" content="True" />
<meta name="Keywords" content={ "Study In Singapore " + ", Jobs in India, Jobs in Noida, Search & Apply Job"}/>
<meta name="description" content={ "Study In Singapore " + " - Singapore is made up of one main island with 63 surrounding isels , and the main island having total area of 682 sq km. There are 4 official languages in Singapore i.e Malays, Mandarin , Tamil & English . Now in the 21st century , education has become more critical in shaping country’s future where the knowledge – based eaconomy plays a vital role in global community .   "} />
<link  href={"https://www.rozgar.com/study-in-singapore"} />
<meta name="referrer" content="no-referrer-when-downgrade" />
<meta name="viewport" content="width=device-width, initial-scale=1"/>


<meta property="og:site_name" content="Rozgar.com" />
<meta property="og:title"content={ "Study In Singapore - Rozgar.com"} />
<meta property="og:description" content={ "Study In Singapore" + " - Singapore is made up of one main island with 63 surrounding isels , and the main island having total area of 682 sq km. There are 4 official languages in Singapore i.e Malays, Mandarin , Tamil & English . Now in the 21st century , education has become more critical in shaping country’s future where the knowledge – based eaconomy plays a vital role in global community .   "} />
<meta property="og:url" content= {"https://www.rozgar.com/study-in-singapore"}  />
<meta property="og:image" content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png" />
<meta property="og:image:width" content="4000" />
<meta property="og:image:height" content="6000" />

<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content={"Study In Singapore  - Rozgar.com"} />
<meta name="twitter:description"  content={ "Study In Singapore " + " - Singapore is made up of one main island with 63 surrounding isels , and the main island having total area of 682 sq km. There are 4 official languages in Singapore i.e Malays, Mandarin , Tamil & English . Now in the 21st century , education has become more critical in shaping country’s future where the knowledge – based eaconomy plays a vital role in global community .   "} />
<meta name="twitter:url"content= {"https://www.rozgar.com/study-in-singapore"} />
<meta name="twitter:image"  content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png"  />
<meta name="twitter:label1" content="Written by" />
<meta name="twitter:data1" content="Smita Nag" />
<meta name="twitter:label2" content="Filed under" />
<meta name="twitter:data2" content="Career Advice, Career Insights" />
<meta name="twitter:site" content="@rozgar_india" />
</Helmet>
        <StudyInSingapores/>
      </React.Fragment>
    )
  }
}
