import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async';
import JobsByLocation from '../../components/jobsByLocation/jobsByLocation'
import constant from '../../constant'
import { capFirstLetterInSentence } from '../../utils';
export default class jobsByLocation extends Component {
    constructor(props){
        super(props);
        this.state ={
        }
    }
    componentDidMount(){
        document.title = constant.title.JobsByLocation
        window.scrollTo(0, 0)
    }
    
  render() {
   
    return (
      
      <React.Fragment>
            <Helmet >


<title > Browse Jobs by Company, Location, Skills, Designation & Industry | Rozgar.com Overview  – Jobs, Work Culture - Rozgar.com</title>
<meta name="HandheldFriendly" content="True" />
<meta name="Keywords" content={ "Browse Jobs by Company, Location, Skills, Designation & Industry" + ", Jobs in India, Jobs in Noida, Search & Apply Job"}></meta>
<meta name="description" content={ "Browse Jobs by Company, Location, Skills, Designation & Industry" + "- Search, find and apply to job opportunities at Rozgar.com - India's leading Job Portal. Bring your perception, imagination and healthy neglect for the impossible. For query call us at +91-9311744658 or email us at contact@rozgar.com"} />
<link  href={"https://www.rozgar.com/jobs-by-location"} />
<meta name="referrer" content="no-referrer-when-downgrade" />
<meta name="viewport" content="width=device-width, initial-scale=1"/>


<meta property="og:site_name" content="Rozgar.com" />
<meta property="og:title"content={ "Browse Jobs by Company, Location, Skills, Designation & Industry"} />
<meta property="og:description" content={"Browse Jobs by Company, Location, Skills, Designation & Industry" + "- Search, find and apply to job opportunities at Rozgar.com - India's leading Job Portal. Bring your perception, imagination and healthy neglect for the impossible. For query call us at +91-9311744658 or email us at contact@rozgar.com"} />
<meta property="og:url" content= {"https://www.rozgar.com/jobs-by-location"}  />
<meta property="og:image" content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png" />
<meta property="og:image:width" content="4000" />
<meta property="og:image:height" content="6000" />

<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content={"Browse Jobs by Company, Location, Skills, Designation & Industry"} />
<meta name="twitter:description"  content={"Browse Jobs by Company, Location, Skills, Designation & Industry" + "- Search, find and apply to job opportunities at Rozgar.com - India's leading Job Portal. Bring your perception, imagination and healthy neglect for the impossible. For query call us at +91-9311744658 or email us at contact@rozgar.com"} />
<meta name="twitter:url"content= {"https://www.rozgar.com/jobs-by-location"} />
<meta name="twitter:image"  content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png"  />
<meta name="twitter:label1" content="Written by" />
<meta name="twitter:data1" content="Smita Nag" />
<meta name="twitter:label2" content="Filed under" />
<meta name="twitter:data2" content="Career Advice, Career Insights" />
<meta name="twitter:site" content="@rozgar_india" />
</Helmet>
          <JobsByLocation/>
      </React.Fragment>
    )
  }
}
