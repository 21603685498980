import React, { Component } from 'react'
import Coupons from '../../components/myAccount/Coupon'
export default class Coupon extends Component {
  render() {
    return (
     <React.Fragment>
      <Coupons/>
     </React.Fragment>
    )
  }
}
