import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async';
import EditProfile from '../../components/myAccount/editProfile'
import constant from '../../constant'
import { capFirstLetterInSentence, getsessionStorage, getStorage } from '../../utils';

export default class editProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: getStorage(constant.keys.cd),
      auth: getStorage(constant.keys.ctoken),
      loginData: getStorage(constant.keys.loginData),
      addUpdate: getsessionStorage(constant.keys.addAndUpdate),
      data: getsessionStorage('saveJobId')
    }
  }
  componentDidMount() {
    // document.title = constant.title.EditProfile
    window.scrollTo(0, 0)
    const { JOB_ID } = this.state.addUpdate ? this.state.addUpdate : ''
    // if(JOB_ID && this.state.detail){
    //   this.props.history.push(constant.component.recommendedJobs.url)
    // }
    if (!this.state.detail) {
      this.props.history.push(constant.component.signin.url)
    }
    // if(this.state.data && this.state.detail){
    //   this.props.history.push(constant.component.savedJobs.url)
    // }
  }
  render() {
    return (
      <React.Fragment>
                        <Helmet >


{/* <title title={capFirstLetterInSentence(window.location.pathname.replace('/','').split('/').pop().split('-').join(' '))}>{ capFirstLetterInSentence(window.location.pathname.replace('/','').split('/').pop().split('-').join(' '))}</title> */}
 <title> edit Profile , rozgar.com </title>   
 <meta name="HandheldFriendly" content="True" />
<meta name="Keywords" content={  "edit profile page "+ ", Jobs in India, Jobs in Noida, Search & Apply Job"}></meta>
<meta name="description" content={" Search, find and apply to job opportunities at Rozgar.com - India's leading Job Portal. Bring your perception, imagination and healthy neglect for the impossible. For query call us at +91-9311744658 or email us at contact@rozgar.com"} />
<link rel="canonical"  href={"http://localhost:3000/edit-profile"} />
<meta name="referrer" content="no-referrer-when-downgrade" />
<meta name="viewport" content="width=device-width, initial-scale=1"/>


</Helmet>
        <EditProfile history={this.props.history} />
      </React.Fragment>
    )
  }
}
