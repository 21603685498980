import { height } from '@mui/system';
import React, { useState } from 'react';

import { useLinkedIn } from 'react-linkedin-login-oauth2';
// You can use provided image shipped by this package or using your own
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
// import linkedin from '../../assets/images/LinkedInButton.png';

export default function LinkedInPage() {
    var myWindow = window;
    const { linkedInLogin } = useLinkedIn({

        clientId: '86g79ix2lcuzek',
        scope: 'r_liteprofile r_emailaddress',
        redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
        onSuccess: (code) => {
            console.log(code)

            window.close()
        },
        onError: (error) => {
            console.log('error', error);
        },
    });

    return (
        <img
            onClick={linkedInLogin}
            src={linkedin}
            alt="Sign in with Linked In"
            style={{ maxWidth: '210px', cursor: 'pointer', height: '41px' }}
        />
    );
}