import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async';
import StudyInIrelands from '../../components/StudyAbroad/StudyInIreland';
import constant from '../../constant';
import { capFirstLetterInSentence } from '../../utils';

export default class StudyInIreland extends Component {
    constructor(props){
        super(props);
        this.state={

        }

    }
    componentDidMount(){
        document.title = constant.title.studyInIreland
    }
  render() {
    return (
      <React.Fragment>
         <Helmet >


<title >Study in Ireland - Rozgar.com</title>
<meta name="HandheldFriendly" content="True" />
<meta name="Keywords" content={ "Study in Ireland " + ", Jobs in India, Jobs in Noida, Search & Apply Job"}/>
<meta name="description" content={ "Study in Ireland " + " - Ireland is a sovereign country on the island of Ireland, with its neighbour Northern Ireland being a part of the United Kingdom.   "} />
<link  href={"https://www.rozgar.com/study-in-irelandy"} />
<meta name="referrer" content="no-referrer-when-downgrade" />
<meta name="viewport" content="width=device-width, initial-scale=1"/>


<meta property="og:site_name" content="Rozgar.com" />
<meta property="og:title"content={ "Study in Ireland - Rozgar.com"} />
<meta property="og:description" content={ "Study in Ireland" + " - Ireland is a sovereign country on the island of Ireland, with its neighbour Northern Ireland being a part of the United Kingdom.   "} />
<meta property="og:url" content= {"https://www.rozgar.com/study-in-irelandy"}  />
<meta property="og:image" content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png" />
<meta property="og:image:width" content="4000" />
<meta property="og:image:height" content="6000" />

<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content={"Study in Ireland - Rozgar.com"} />
<meta name="twitter:description"  content={ "Study in Ireland" + " - Ireland is a sovereign country on the island of Ireland, with its neighbour Northern Ireland being a part of the United Kingdom.   "} />
<meta name="twitter:url"content= {"https://www.rozgar.com/study-in-irelandy"} />
<meta name="twitter:image"  content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png"  />
<meta name="twitter:label1" content="Written by" />
<meta name="twitter:data1" content="Smita Nag" />
<meta name="twitter:label2" content="Filed under" />
<meta name="twitter:data2" content="Career Advice, Career Insights" />
<meta name="twitter:site" content="@rozgar_india" />
</Helmet>
        <StudyInIrelands/>
      </React.Fragment>
    )
  }
}
