import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async';
import StudyInNewZealands from '../../components/StudyAbroad/StudyInNewZealand';
import constant from '../../constant';

export default class StudyInNewZealand extends Component {
    constructor(props){
        super(props);
        this.state={

        }
    }
    componentDidMount(){
        document.title = constant.title.studyInNewZealand
    }
  render() {
    return (
      <React.Fragment>
          <Helmet >


<title >Study In New Zealand - Rozgar.com</title>
<meta name="HandheldFriendly" content="True" />
<meta name="Keywords" content={ "Study In New Zealand " + ", Jobs in India, Jobs in Noida, Search & Apply Job"}/>
<meta name="description" content={ "Study In New Zealand " + " - New Zealand is an island in the south western Pacific Ocean, and is perhaps always overshadowed by its larger neighbour Australia. But New Zealand is perhaps the most underrated country in the world, with its high standards of quality of life, education, protection of civil liberties, government transparency, and economic freedom as well as its advanced market economy, with the country ranking third in the Index of Economic Freedom.   "} />
<link  href={"https://www.rozgar.com/study-In-new-zealand"} />
<meta name="referrer" content="no-referrer-when-downgrade" />
<meta name="viewport" content="width=device-width, initial-scale=1"/>


<meta property="og:site_name" content="Rozgar.com" />
<meta property="og:title"content={ "Study In New Zealand - Rozgar.com"} />
<meta property="og:description" content={ "Study In New Zealand" + " - New Zealand is an island in the south western Pacific Ocean, and is perhaps always overshadowed by its larger neighbour Australia. But New Zealand is perhaps the most underrated country in the world, with its high standards of quality of life, education, protection of civil liberties, government transparency, and economic freedom as well as its advanced market economy, with the country ranking third in the Index of Economic Freedom.   "} />
<meta property="og:url" content= {"https://www.rozgar.com/study-In-new-zealand"}  />
<meta property="og:image" content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png" />
<meta property="og:image:width" content="4000" />
<meta property="og:image:height" content="6000" />

<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content={"Study In New Zealand  - Rozgar.com"} />
<meta name="twitter:description"  content={ "Study In New Zealand " + " - New Zealand is an island in the south western Pacific Ocean, and is perhaps always overshadowed by its larger neighbour Australia. But New Zealand is perhaps the most underrated country in the world, with its high standards of quality of life, education, protection of civil liberties, government transparency, and economic freedom as well as its advanced market economy, with the country ranking third in the Index of Economic Freedom.   "} />
<meta name="twitter:url"content= {"https://www.rozgar.com/study-In-new-zealand"} />
<meta name="twitter:image"  content="https://rozgarlogo.s3.ap-south-1.amazonaws.com/download.png"  />
<meta name="twitter:label1" content="Written by" />
<meta name="twitter:data1" content="Smita Nag" />
<meta name="twitter:label2" content="Filed under" />
<meta name="twitter:data2" content="Career Advice, Career Insights" />
<meta name="twitter:site" content="@rozgar_india" />
</Helmet>
        <StudyInNewZealands/>
      </React.Fragment>
    )
  }
}
